<template>
    <information title="Curve integráció" width="700">
        <ul>
            <li>A <span class="info-highlighted">Curve</span> integráció engedélyezésével a mobilon lévő curve adatok beszinkronizálhatóak a költség kezelő
                rendszerbe megosztással
            </li>
            <li>Minden <span class="info-highlighted">Curve</span>-ös kategóriához hozzá kell rendelve lennie egy költség kezelő kategóriának (kategória
                alapú összerendelés)
            </li>
            <ul>
                <li>A kategória cimkéje a <span class="info-highlighted">Curve</span> kategória</li>
                <li>A kiválasztandó kategória pedig a költségkezelőben ennek megfelelő kategória</li>
            </ul>
            <li>Ezen felül lehetőség van szöveg alapú összerendelés kiválasztásához</li>
            <ul>
                <li>Az összerendelések priorítás szerint csökkenő sorrendben vannak</li>
                <li>A sorrendet változtatni a <v-icon color="primary">mdi-drag-vertical</v-icon> elem megragadásával és mozgatásával lehet</li>
                <li>A keresendő szöveg mező tartamát a <span class="info-highlighted">Curve</span>-ön keresztüli vásárlás helyének illetve megjegyzésének
                    mezőjében keresi
                </li>
                <li>A megjegyzésbe beírt szöveg pedig találat esetén a létrejövő költség megjegyzés mezőjébe íródik</li>
            </ul>
        </ul>
    </information>
</template>

<script>
import Information from "@/utils/information/Information";

export default {
    name: "curve-information",
    components: {Information},
};
</script>

<style scoped>
.info-highlighted {
    background-color: #eee;
    color: #334455;
    padding: 2px 2px;
    border-radius: 2px;
}
</style>
