<template>
    <v-snackbar v-model="visible" top centered dark :color="color" :timeout="timeout" class="mt-n10">
        <span class="vdp-message">{{ message }}</span>
        <template #action>
            <v-btn icon @click="visible = false">
                <v-icon small>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    name: "alert",

    data: () => ({
        visible: false,
        message: null,
        color: null,
        timeout: null
    }),

    methods: {
        showMessage({message, color, timeout} = {}) {
            this.message = message;
            this.color = color;
            this.timeout = timeout;
            this.visible = true;
        }
    }
};
</script>

<style scoped>
.vdp-message {
    white-space: pre-wrap;
}
</style>
