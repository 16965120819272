<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="auto">
                <v-card>
                    <v-card-title>
                        <span class="headline">Változás történet</span>
                    </v-card-title>
                    <v-card-text>
                        <v-timeline dense align-top>
                            <v-timeline-item v-for="(version, versionIdx) in versions" :key="versionIdx" small
                                             :color="getColor(versionIdx)">
                                <v-row no-gutters>
                                    <v-col cols="auto">
                                        <div class="subtitle-1 pr-16">{{ version.version }}</div>
                                    </v-col>
                                    <v-col>
                                        <div v-for="(change, changeIdx) in version.changes" :key="changeIdx">
                                            <br v-if="changeIdx"/>
                                            <div class="subtitle-2">{{ change.section }}</div>
                                            <ul>
                                                <li v-for="(item) in change.changes" :key="item">{{ item }}</li>
                                            </ul>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-timeline-item>
                        </v-timeline>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {HISTORY} from "@/history/data";

export default {
    name: "history",

    data: () => ({
        versions: HISTORY.versions
    }),

    methods: {
        getColor(idx) {
            return idx === 0 ? 'success' : 'primary';
        }
    }
};
</script>
