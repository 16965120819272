<template>
    <div>

        <form-dialog ref="formDialog" @save="$emit('save')" @refresh="refreshList">
            <template #default="{on}">
                <slot name="form" v-bind="{id: actualItemId, on: on}"/>
            </template>
        </form-dialog>

        <v-card min-width="400">
            <v-card-title class="headline">
                <v-row dense>
                    <v-col>
                        <div>{{ title }}</div>
                    </v-col>
                </v-row>
                <v-row dense class="justify-end">
                    <v-col cols="auto">
                        <search-field v-model="search" style="width: 200px;"/>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <simple-table :headers="headersWithActions" :items="items" :bottom-padding="bottomPadding"
                              :search="search">
                    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
                        <slot :name="slot" v-bind="scope"/>
                    </template>
                    <template #item.actions="{item}">
                        <v-btn icon color="primary" @click="openForm('Módosítás', item.id)">
                            <v-icon>$edit</v-icon>
                        </v-btn>
                        <v-btn icon color="error" class="ml-2" @click="deleteItem(item.id)" v-if="deleter">
                            <v-icon>$delete</v-icon>
                        </v-btn>
                    </template>
                </simple-table>
                <v-fab-transition>
                    <v-btn color="primary" dark absolute right fab small class="mr-n4 mt-n6"
                           @click="openForm('Rögzítés')">
                        <v-icon>$create</v-icon>
                    </v-btn>
                </v-fab-transition>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>

import SimpleTable from "@/utils/table/SimpleTable";
import FormDialog from "@/utils/form/FormDialog";
import SearchField from "@/utils/search/SearchField";

export default {
    name: "simple-crud-list",
    components: {SearchField, FormDialog, SimpleTable},
    props: {
        title: String,
        headers: Array,
        getter: Function,
        deleter: Function,
        deleteTitle: String,
        deleteText: String,
        bottomPadding: {type: [String, Number], default: 72}
    },

    data: () => ({
        search: null,
        items: [],
        actualItemId: null
    }),

    computed: {
        headersWithActions() {
            let headers = this.headers;
            headers.push({text: "", value: "actions", sortable: false, width: this.deleter ? 120 : 80});
            return headers;
        }
    },

    methods: {
        openForm(title, id) {
            this.actualItemId = id;
            this.$refs.formDialog.openFormDialog(title);
        },

        deleteItem(id) {
            let deletionMessage = {
                title: this.deleteTitle,
                text: this.deleteText,
                acceptText: "Törlés"
            };
            this.$confirm(deletionMessage)
                .then(() => this.deleter(id))
                .then(() => this.$success('Sikeres törlés'))
                .then(() => this.refreshList());
        },

        refreshList() {
            this.getter().then(items => this.items = items);
        }
    },

    mounted() {
        this.refreshList();
    }
};
</script>
