<template>
    <v-container fluid>
        <v-row>
            <v-col cols="auto">
                <v-form ref="form">
                    <v-card>
                        <v-card-title class="headline">
                            <v-row dense>
                                <v-col>
                                    <curve-information/>
                                </v-col>
                            </v-row>
                            <v-row dense class="justify-end">
                                <v-col cols="auto">
                                    <v-switch label="Engedélyezett" v-model="curve.enabled"/>
                                </v-col>
                            </v-row>
                            <v-btn color="primary" style="margin-top: 122px; margin-right: 20px" dark absolute  right
                                   fab small top v-if="curve.enabled && tab === 1" @click.native="addTextMapping">
                                <v-icon>$create</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <v-tabs v-model="tab">
                                <v-tab>Kategória alapú összerendelés</v-tab>
                                <v-tab-item>
                                    <scrollable-div bottom-padding="82" class="px-2 pt-5">
                                        <curve-category-based-mapping v-for="(m, idx) in curve.categoryMappings"
                                                                      :key="idx" :categories="categories"
                                                                      v-model="curve.categoryMappings[idx]"
                                                                      :disabled="!curve.enabled"/>
                                    </scrollable-div>
                                </v-tab-item>
                                <v-tab>Szöveg alapú összerendelés</v-tab>
                                <v-tab-item>
                                    <scrollable-div bottom-padding="82" class="pt-5">
                                        <draggable class="v-row" :list="curve.textBasedMappings" handle=".handle">
                                            <curve-text-based-mapping v-for="(m, idx) in curve.textBasedMappings"
                                                                      :key="idx" :disabled="!curve.enabled"
                                                                      v-model="curve.textBasedMappings[idx]"
                                                                      :categories="categories"
                                                                      @delete="removeTextMapping(idx)"
                                                                      ref="textBasedMapping"/>
                                        </draggable>
                                    </scrollable-div>
                                </v-tab-item>
                            </v-tabs>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <cancel-button @click="refresh">Mégsem</cancel-button>
                            <ok-button @click="save">Mentés</ok-button>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ScrollableDiv from "@/utils/ScrollableDiv/ScrollableDiv";
import CurveTextBasedMapping from "@/curve/components/CurveTextBasedMapping";
import CurveCategoryBasedMapping from "@/curve/components/CurveCategoryBasedMapping";
import CancelButton from "@/utils/form/CancelButton";
import OkButton from "@/utils/form/OkButton";
import draggable from "vuedraggable";
import CurveInformation from "@/curve/components/CurveInformation";

export default {
    name: "curve-settings",
    components: {
        CurveInformation,
        OkButton, CancelButton, CurveCategoryBasedMapping, CurveTextBasedMapping, ScrollableDiv, draggable},
    data: () => ({
        curve: {
            enabled: false,
            categoryMappings: [{id: null, name: "test", category: null}],
            textBasedMappings: []
        },
        categories: [],
        tab: 0
    }),

    methods: {
        refresh() {
            this.$api.categories.getCategories().then(cats => this.categories = cats);
            this.$api.integration.getCurveIntegration().then(response => this.curve = response);
        },

        save() {
            if (!this.$refs.form.validate()) {
                return;
            }

            this.$api.integration.saveCurveIntegration(this.curve)
                .then(() => this.$success("Sikeres mentés"))
                .then(() => this.refresh());
        },

        addTextMapping() {
            this.curve.textBasedMappings.splice(0, 0, {id: null, filter: null, category: null, description: null});
            this.$nextTick(function () {
                this.$refs.textBasedMapping[0].$refs.filter.focus();
            });
        },

        removeTextMapping(idx) {
            this.curve.textBasedMappings.splice(idx, 1);
        },
    },

    mounted() {
        this.refresh();
    }
};
</script>
