export default {
    namespaced: true,

    state: {
        visible: false,
    },

    mutations: {
        toggle_visibility(state) {
            state.visible = !state.visible;
        }
    },

    actions: {
        toggleMenuVisibility({commit}) {
            return new Promise(() => {
                commit('toggle_visibility');
            });
        }
    }
};
