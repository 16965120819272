<template>
    <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
        <template v-slot:activator="{ on }">
            <v-text-field :value="hunDate" :label="label" append-icon="mdi-calendar" readonly v-on="on" dense/>
        </template>
        <v-date-picker v-model="date" scrollable :first-day-of-week="1" locale="hu-hu">
            <v-spacer></v-spacer>
            <cancel-button @click="modal = false">Mégsem</cancel-button>
            <ok-button @click="save">Kiválaszt</ok-button>
        </v-date-picker>
    </v-dialog>
</template>

<script>
import CancelButton from "@/utils/form/CancelButton";
import OkButton from "@/utils/form/OkButton";
import {toHunDate} from "@/utils/DatePicker/DateUtils";

export default {
    name: "date-picker",
    components: {OkButton, CancelButton},
    props: {
        value: String,
        label: String
    },

    data: () => ({
        modal: false,
        date: null
    }),

    computed: {
        hunDate() {
            return toHunDate(this.value);
        }
    },

    methods: {
        save() {
            this.modal = false;
            this.$emit('input', this.date);
        }
    },

    watch: {
        value: {
            handler(newValue) {
                this.date = newValue;
            },
            immediate: true
        }
    }
};
</script>
