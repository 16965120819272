<template>
    <simple-crud-list v-bind="options" @save="save">
        <template #form="{id, on}">
            <user-form ref="form" :id="id" v-on="on"/>
        </template>
        <template #item.admin="{ value }">
            <boolean-icon :value="value"/>
        </template>
    </simple-crud-list>

</template>

<script>
import SimpleCrudList from "@/utils/crud/SimpleCrudList";
import UserForm from "@/auth/components/UserForm";
import BooleanIcon from "@/utils/icons/BooleanIcon";

export default {
    name: "user-list",
    components: {BooleanIcon, UserForm, SimpleCrudList},

    data: () => ({}),

    computed: {
        options() {
            return {
                headers: [
                    {text: "Email", value: "email"},
                    {text: "Csoport", value: "usergroup"},
                    {text: "Adminisztrátor", value: "admin", align: "center"}
                ],
                title: "Felhasználók",
                getter: this.$api.users.getUsers,
                deleter: this.$api.users.deleteUser,
                deleteTitle: "Valóban törölni akarja a felhasználót?",
                deleteText: "A felhasználó törlés után nem visszaállítható és nem is tud belépni."
            };
        }
    },

    methods: {
        save() {
            this.$refs.form.save();
        },
    }
};
</script>
