<template>
    <div class="pocket">
        <form-dialog ref="formDialog" @save="saveCardNumbers" max-width="400">
            <template #default="{on}">
                <pocket-card-numbers v-model="pocket.cardNumbers" :other-card-numbers="otherCardNumbers"
                                     ref="pocketCardNumbers" v-on="on"/>
            </template>
        </form-dialog>

        <v-divider class="pt-4" v-if="!first"/>
        <v-row dense class="mx-4 align-center">
            <v-col cols="auto">
                <v-icon color="primary" class="handle" x-large>
                    mdi-drag-vertical
                </v-icon>
            </v-col>
            <v-col>
                <v-row dense>
                    <v-col>
                        <div>
                            <v-text-field label="Név" v-model="pocket.name" dense :rules="rules.name" ref="name">
                                <template #append-outer>
                                    <v-btn-toggle class="mt-n1 mr-3" :value="toggles" multiple dense>
                                        <v-btn icon @click="pocket.saved = !pocket.saved" :value="0" color="primary">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on : tt }">
                                                    <v-icon v-on="tt">mdi-piggy-bank</v-icon>
                                                </template>
                                                Megtakarítás
                                            </v-tooltip>
                                        </v-btn>
                                    </v-btn-toggle>
                                </template>
                            </v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="3">
                        <v-badge bottom :color="pocket.cardNumbers.length ? 'primary' : 'white'">
                            <template v-slot:badge v-if="pocket.cardNumbers.length">
                                <span>{{ pocket.cardNumbers.length }}</span>
                            </template>
                            <v-btn color="green" fab dark small class="mr-2" @click="openCardNumbers">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on : tt }">
                                        <v-icon v-on="tt">mdi-credit-card</v-icon>
                                    </template>
                                    Kártya számok
                                </v-tooltip>
                            </v-btn>
                        </v-badge>
                    </v-col>
                    <v-col cols="auto" class="mt-0">
                        <icon-picker v-model="pocket.icon" :color.sync="pocket.color"/>
                    </v-col>
                    <v-col cols="1" class="text-center">
                        <v-btn color="error" icon small class="mr-n1" v-show="!pocket.costAssigned"
                               @click="$emit('delete')">
                            <v-icon>$delete</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-1 ml-0">
                    <div class="caption">Típus*</div>
                </v-row>
                <v-row no-gutters class="mt-n4">
                    <v-radio-group v-model="pocket.type" :rules="rules.type" row>
                        <v-radio v-for="type in types" :key="type.id" :label="type.name" :value="type.id"/>
                    </v-radio-group>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import VuetifyJetValidator from "vuetify-jet-validator";
import PocketCardNumbers from "@/pocket/components/PocketCardNumbers";
import FormDialog from "@/utils/form/FormDialog";
import IconPicker from "@/utils/IconPicker";

const validator = new VuetifyJetValidator();

export default {
    name: "pocket",
    components: {IconPicker, FormDialog, PocketCardNumbers},
    props: {
        value: {type: Object, required: true},
        first: Boolean,
        nonUniqueNames: Set,
        types: Array,
        allPockets: Array
    },

    data() {
        return {
            rules: {
                name: [
                    validator.rules.required("Név megadása kötelező"),
                    v => !this.nonUniqueNames.has(v) || "Nem egyedi típus név"
                ],
                type: [validator.rules.required("Típus megadása kötelező")]
            },
        };
    },

    computed: {
        pocket: {
            get() {
                return this.value;
            },
            set(mapping) {
                this.$emit('input', mapping);
            }
        },

        otherCardNumbers() {
            return this.allPockets
                .filter(it => it !== this.pocket)
                .flatMap(pocket => pocket.cardNumbers
                    .map(card => ({number: card, pocket: pocket.name})));
        },

        toggles() {
            return this.pocket.saved ? [0] : [];
        }
    },

    methods: {
        focus() {
            this.$refs.name.focus();
        },

        openCardNumbers() {
            this.$refs.formDialog.openFormDialog("Kártya azonosítók");
        },

        saveCardNumbers() {
            this.$refs.pocketCardNumbers.save();
        },

    },

    watch: {
        nonUniqueNames() {
            this.$refs.name.validate();
        }
    }
};
</script>

<style scoped>
.handle {
    cursor: move;
}
</style>
