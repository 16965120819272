<template>
    <v-data-table hide-default-footer disable-pagination v-bind="options" v-on="$listeners" ref="table"
                  v-resize="resizeTableMaxHeight" class="table-striped">
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope"/>
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: "simple-table",
    props: {
        items: Array,
        headers: Array,
        search: String,
        bottomPadding: {
            type: [Number, String],
            default: 16,
        },
        loading: Boolean,
        showSelect: Boolean
    },

    data: () => ({
        tableHeight: 3000
    }),

    computed: {
        options() {
            return {
                items : this.items,
                headers: this.headers,
                search: this.search,
                'fixed-header': true,
                height: this.tableHeight,
                loading: this.loading,
                'show-select': this.showSelect
            };
        }
    },

    watch: {
        items() {
            this.resizeTableMaxHeight();
            this.refreshForcer++;
        },
        bottomPadding() {
            this.resizeTableMaxHeight();
        },
    },

    methods: {
        resizeTableMaxHeight() {
            this.$nextTick(function () {
                const tableWrapper = this.$refs.table.$el;
                const tableIndex = tableWrapper.childNodes[0].className.includes('v-data-table__wrapper') ? 0 : 1;
                const table = tableWrapper.childNodes[tableIndex].childNodes[0];
                const abovePanelHeight = tableIndex === 1 ? tableWrapper.childNodes[0].getBoundingClientRect().height : 0;
                const footerHeight = tableWrapper.childNodes.length > tableIndex + 1 ? tableWrapper.childNodes[tableIndex + 1].getBoundingClientRect().height : 0;
                const maxHeight = window.innerHeight - tableWrapper.getBoundingClientRect().top - this.bottomPadding - abovePanelHeight - footerHeight;
                this.tableHeight = maxHeight > table.getBoundingClientRect().height ? null : maxHeight;
            });
        },
    },

    mounted() {
        this.resizeTableMaxHeight();
    }
};
</script>
<style>
.table-striped tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .03);
}
</style>
