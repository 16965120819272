export function toHunDate(value) {
    if (!value) {
        return null;
    } else if (typeof value == "string") {
        return value.replace(/-/g, '.');
    } else if (Array.isArray(value) && value.length > 0) {
        return `${toHunDate(value[0])} - ${value.length > 1 ? toHunDate(value[1]) : ''}`;
    }
    return null;
}

export function thisMonth() {
    return new Date().toISOString().substr(0, 7);
}

export function today() {
    return new Date().toISOString().substr(0, 10);
}

export function toRange(date) {
    if (typeof date == "string") {
        return ({from: date, to: date});
    } else if (Array.isArray(date) && date.length == 2) {
        return ({from: date[0], to: date[1]});
    }
    return null;
}

export function toPath(date) {
    if (typeof date == "string") {
        return (`${date}/${date}`);
    } else if (Array.isArray(date) && date.length == 2) {
        return (`${date[0]}/${date[1]}`);
    }
    return null;
}
