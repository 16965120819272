import Vue from 'vue';

const defaults = {
    dialogMaxWidth: 500,
    cancelText: "Mégsem",
    acceptText: "Ok"
};

Vue.mixin({
    methods: {
        $_dialog_show(type, message) {
            const root = this.$root;
            return new Promise(function (resolve, reject) {
                new Promise(function (_resolve) {
                    if (typeof message === "string") {
                        message = {
                            title: "",
                            text: message
                        };
                    }
                    const _message = {};
                    Object.assign(_message, defaults, message);
                    root.$children[0].$refs.simpleDialog.showMessage(_message, type, _resolve);
                }).then(result => {
                    if (result === false) {
                        reject();
                    } else {
                        resolve(result);
                    }
                });
            });
        },
        $alert(message) {
            return this.$_dialog_show("alert", message);
        },
        $confirm(message) {
            return this.$_dialog_show("confirm", message);
        },
        $prompt(message) {
            return this.$_dialog_show("prompt", message);
        },
    },
});
