import Vue from 'vue';

const apis = require.context('@', true, /.*\.api\.js$/i);

let _api = {};

const API = {
    install(vue) {
        apis.keys()
            .map(apis) // import module
            .map(m => m.default) // get `default` export from each resolved module
            .forEach(a => Object.assign(_api, a(vue.prototype)));

        vue.prototype.$api = _api;
    }
};

Vue.use(API);
export default API;
