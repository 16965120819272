<template>
    <v-row no-gutters class="align-center" :style="`height: ${this.height - 8}px`">
        <v-col cols="5">
            <name-with-icon :value="value"/>
        </v-col>
        <v-col cols="3" class="text-center">
            <v-progress-circular v-if="value.limit != null" class="text-caption" rotate="-90" size="38" width="2"
                                 :value="value.limit > 100 ? 100 : value.limit"
                                 :color="value.limit > 100 ? 'red' : 'green'" style="margin-top: -3px">
                {{ value.limit }}%
            </v-progress-circular>
        </v-col>
        <v-col cols="4" class="text-right">
            <div>{{ value.cost | asCurrency }}</div>
            <div class="mt-n1" v-if="this.value.unexpected">
                <cost-value :value="{cost: formattedUnexpected, unexpected: true}" class="caption"/>
            </div>
        </v-col>
    </v-row>
</template>
<script>
import NameWithIcon from "@/utils/icons/NameWithIcon";
import {toCurrency} from "@/utils/CurrencyField/CurrencyUtils";
import CostValue from "@/cost/components/CostValue";

export default {
    name: 'category-summary',
    components: {NameWithIcon, CostValue},
    props: {
        value: {type: Object, required: true},
        height: {type: [String, Number], required: true}
    },

    computed: {
        unexpectedPercent() {
            return this.value.cost === 0 ? 0 : Math.round(this.value.unexpected / this.value.cost * 100);
        },
        formattedUnexpected() {
            return `${toCurrency(this.value.unexpected)} (${this.unexpectedPercent}%)`;
        }
    },

    filters: {
        asCurrency: value => toCurrency(value)
    },
};
</script>
