var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(_vm.isOnAndroid)?_c('app-bar-menu',{directives:[{name:"touch",rawName:"v-touch",value:({down: function () { return _vm.refreshPage(); }}),expression:"{down: () => refreshPage()}"}],scopedSlots:_vm._u([{key:"default",fn:function(ref){
var color = ref.color;
return [_c('span',{class:[(color + "--text"), 'headline-2']},[_vm._v("Egyenleg")]),_c('v-spacer'),_c('v-checkbox',{staticClass:"mt-5",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"primary--text"},[_vm._v("Átlag számítás")])]},proxy:true}],null,true),model:{value:(_vm.average),callback:function ($$v) {_vm.average=$$v},expression:"average"}}),_c('month-filter-picker',{attrs:{"color":color},model:{value:(_vm.monthRange),callback:function ($$v) {_vm.monthRange=$$v},expression:"monthRange"}})]}}],null,false,3381569006)}):_vm._e(),_c('v-col',{directives:[{name:"touch",rawName:"v-touch",value:({down: function () { return _vm.refreshPage(); }}),expression:"{down: () => refreshPage()}"}],attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? 12 : 3}},[(!_vm.isOnAndroid)?_c('v-card',[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('month-filter-picker',{model:{value:(_vm.monthRange),callback:function ($$v) {_vm.monthRange=$$v},expression:"monthRange"}})],1),_c('v-col',{staticClass:"mt-n3"},[_c('v-row',{staticClass:"justify-end",attrs:{"no-gutters":""}},[_c('v-checkbox',{attrs:{"label":"Átlag számítás"},model:{value:(_vm.average),callback:function ($$v) {_vm.average=$$v},expression:"average"}})],1)],1)],1)],1)],1):_vm._e(),_c('div',{class:_vm.isOnAndroid ? 'mt-n3' : 'mb-6'}),_c('cost-summary',{ref:"summary",scopedSlots:_vm._u([(_vm.isOnAndroid)?{key:"middle",fn:function(ref){
var valueClass = ref.valueClass;
return [_c('div',{class:['text-center', 'mt-n4', valueClass]},[_vm._v(" "+_vm._s(_vm._f("asHunDate")(_vm.monthRange))+" ")])]}}:null,{key:"default",fn:function(ref){
var summary = ref.summary;
var labelClass = ref.labelClass;
var valueClass = ref.valueClass;
return [_c('div',{class:labelClass},[_vm._v("Maradék")]),_c('div',{class:[valueClass, 'mb-n8']},[_vm._v(_vm._s(_vm._f("asCurrency")(summary.difference)))]),(!_vm.isOnAndroid)?_c('v-fab-transition',[_c('v-btn',{staticClass:"mb-n16 mr-n9",attrs:{"color":"primary","dark":"","fab":"","right":"","small":""},on:{"click":_vm.refreshPage}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1):_vm._e()]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? 12 : 3}},[_c('v-card',{class:{'mt-n3': _vm.isOnAndroid},attrs:{"flat":_vm.isOnAndroid}},[(!_vm.isOnAndroid)?_c('v-card-title',{staticClass:"text-h5"},[_c('v-row',{attrs:{"dense":""}},[_c('information',{attrs:{"title":"Kategóriák"}},[_c('ul',[_c('li',[_vm._v("Azon kategóriáknál, ahol be lett korlát állítva megjelenik a korláthoz viszonyított arány")]),_c('li',[_vm._v("A váratlan kiadások az összeg alatt találhatóak, az "),_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-alert-outline")]),_vm._v(" ikonnal megjelölve")],1)])])],1),_c('v-row',{staticClass:"justify-end",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('search-field',{staticStyle:{"width":"200px"},model:{value:(_vm.categoryStat.search),callback:function ($$v) {_vm.$set(_vm.categoryStat, "search", $$v)},expression:"categoryStat.search"}})],1)],1)],1):_vm._e(),_c('v-card-text',{class:{'pa-0': _vm.isOnAndroid}},[_c('virtual-scroll-table',_vm._b({attrs:{"store-name":"Dashboard","bottom-padding":_vm.isOnAndroid ? 72 : 28},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var itemHeight = ref.itemHeight;
return [_c('category-summary',{attrs:{"value":item,"height":itemHeight}})]}}])},'virtual-scroll-table',_vm.categoryStat,false))],1)],1)],1),(!_vm.isOnAndroid)?_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? 12 : 6}},[_c('categories-diagram',{ref:"diagram"})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }