<template>
    <v-text-field ref="field" type="tel" suffix="Ft" :value="value | asCurrency" v-bind="options"
                  @input="changeCurrency($event)" :autofocus="autofocus && !isAndroidApp"/>
</template>

<script>
import {toCurrency} from "@/utils/CurrencyField/CurrencyUtils";
import {Plugins} from "@capacitor/core";
import PlatformInfoMixin from "@/utils/platform/PlatformInfoMixin";

const MAX_VALUE = 2_147_483_648; // 2^31

export default {
    name: "currency-field",
    mixins: [PlatformInfoMixin],
    props: {
        value: Number,
        label: String,
        rules: Array,
        autofocus: Boolean
    },
    data: () => ({}),

    computed: {
        options() {
            return ({
                label: this.label,
                rules: this.extendedRules,
                autofocus: this.autofocus
            });
        },
        extendedRules() {
            let extendedRules = [v => this.toNumber(v) < MAX_VALUE || `Kisebbnek kell lennie mint ${MAX_VALUE}`];
            if (this.rules) {
                this.rules.forEach(it => extendedRules.push(v => it(this.toNumber(v))));
            }
            return extendedRules;
        }
    },

    filters: {
        asCurrency: (value) => toCurrency(value)
    },

    methods: {
        changeCurrency(value) {
            this.$emit('input', this.toNumber(value));
        },
        toNumber(value) {
            return value == "" ? 0 : parseInt(value.replace(/[^0-9]/g, ""));
        },
        focus() {
            this.$refs.field.focus();
            if (this.isOnAndroid) {
                Plugins.Keyboard.show();
            }
        }
    },

    mounted() {
        if (this.isAndroidApp && this.autofocus) {
            this.$nextTick(function () {
                this.focus();
            });
        }
    }
};
</script>
