<template>
    <div class="overflow-y-auto" ref="parent" :style="`max-height: ${maxHeight}px`" v-resize="resize" v-intersect="onIntersect"
    :id="`parent-${_uid}`">
        <slot/>
        <span :id="`end-${_uid}`"/>
    </div>
</template>

<script>

export default {
    name: "scrollable-div",
    props: {
        bottomPadding: {type: [Number, String], default: 8}
    },

    data: () => ({
        maxHeight: 100
    }),

    methods: {
        resize() {
            this.$nextTick(function () {
                if (this.$refs["parent"]) {
                    this.maxHeight = window.innerHeight - this.$refs.parent.getBoundingClientRect().top - this.bottomPadding;
                }
            });
        },

        onIntersect (entries) {
            if (entries[0].isIntersecting) {
                this.resize();
            }
        },

        goToEnd() {
            this.$vuetify.goTo(`#end-${this._uid}`, {container: `#parent-${this._uid}`});
        }
    },
};
</script>
