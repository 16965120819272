import {mapGetters} from "vuex";
import {cfaSignIn, cfaSignOut} from "capacitor-firebase-auth";
import PlatformInfoMixin from "@/utils/platform/PlatformInfoMixin";

export default ({
    mixins: [PlatformInfoMixin],

    data: () => ({
        _alreadyLoaded: false
    }),

    computed: {
        ...mapGetters('Authentication', ['isLoggedIn', 'displayName', 'firebaseUser']),
    },

    methods: {
        signIn() {
            cfaSignIn('google.com')
                .subscribe(() => console.log('signed in'), e => alert(e));
        },
        signOut() {
            cfaSignOut().subscribe(() => this.goToLogin(), e => alert(e));
        },
        goToLogin() {
            if (!this.isAndroidApp) {
                this.$router.replace('/');
            }
        },
        _loadWhenSignedIn() {
            if (this.isLoggedIn && !this._alreadyLoaded) {
                this._alreadyLoaded = true;
                this.loadWhenSignedIn();
            }
        },
        loadWhenSignedIn() {
            // NOP
        }
    },

    watch: {
        isLoggedIn() {
            this._loadWhenSignedIn();
        }
    },

    mounted() {
        this._loadWhenSignedIn();
    }
});
