<template>
    <div>
        <v-btn icon color="primary" @click="$emit('edit', item)">
            <v-icon :small="small">$edit</v-icon>
        </v-btn>
        <v-btn icon color="success" class="ml-2" @click="$emit('move', item)">
            <v-icon :small="small">$copy</v-icon>
        </v-btn>
    </div>
</template>
<script>
export default {
    name: 'cost-list-actions',
    props: {
        item: Object,
        small: Boolean
    }
};
</script>
