<template>
    <information title="Zsebek">
        Zseb a különböző költségek tárolási helye.
        <ul>
            <li>Minden zsebnek kötelező és egyedi a neve</li>
            <li>Minden zsebhez megadható egy ikon</li>
            <li>Zsebenként be lehet állítani, hogy megtakarítás típusú-e a zseb (<v-btn icon x-small><v-icon>mdi-piggy-bank</v-icon></v-btn>)</li>
            <li>Minden zsebnek kötelező a típusa, melyet a képernyő jobb oldalán lehet kezelni</li>
            <li>
                <span>A zsebekhez felvehetőek egyedi kártyaszámok <v-btn fab color="green" dark x-small><v-icon>mdi-credit-card</v-icon></v-btn> (pontosabban az utolsó 4 számjegye), melyeket a Curve integrációnál használunk</span>
            </li>
            <li>A zsebek sorrendje felhasználó specifikus; mindenki saját magának adja meg a sorrendet fontosság szempontjából csökkenő sorrendben</li>
            <li>A zseb csak akkor törölhető, ha még nem lett költséghez rendelve</li>
        </ul>
    </information>
</template>

<script>
import Information from "@/utils/information/Information";
export default {
    name: "pocket-information",
    components: {Information}
};
</script>
