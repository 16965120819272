<template>
    <v-row dense class="pt-3 mx-2">
        <v-col cols="auto">
            <v-icon color="primary" class="handle mt-n1" :disabled="disabled" x-large>
                mdi-drag-vertical
            </v-icon>
        </v-col>
        <v-col>
            <v-text-field label="Keresendő szöveg" v-model="mapping.filter" :disabled="disabled" :rules="rules.filter"
                          dense ref="filter"/>
        </v-col>
        <v-col>
            <name-with-icon-select label="Kategória" dense :items="categories" v-model="mapping.category" :disabled="disabled"
                             :rules="rules.category"/>
        </v-col>
        <v-col>
            <v-text-field label="Megjegyzés" v-model="mapping.description" :disabled="disabled" dense/>
        </v-col>
        <v-col cols="auto">
            <v-btn icon class="mx-1" @click="$emit('delete')">
                <v-icon color="error" :disabled="disabled">
                    $delete
                </v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import VuetifyJetValidator from "vuetify-jet-validator";
import NameWithIconSelect from "@/utils/icons/NameWithIconSelect";

const validator = new VuetifyJetValidator();

export default {
    name: "curve-text-based-mapping",
    components: {NameWithIconSelect},
    props: {
        value: {type: Object, required: true},
        disabled: Boolean,
        categories: Array
    },

    data: () => ({
        rules: {
            filter: [validator.rules.required("Keresendő szöveg kötelező")],
            category: [validator.rules.required("Kategória kötelező")],
        }
    }),

    computed: {
        mapping: {
            get() {
                return this.value;
            },
            set(mapping) {
                this.$emit('input', mapping);
            }
        }
    }
};
</script>

<style scoped>
.handle {
    cursor: move;
}
</style>
