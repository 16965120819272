import 'fontsource-roboto/latin.css';
import 'fontsource-roboto/latin-ext.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css';
import '@/curve/css/curve.css';

Vue.use(Vuetify);

import hu from 'vuetify/es5/locale/hu';

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
        values: {
            edit: 'mdi-pencil',
            delete: 'mdi-delete-outline',
            create: 'mdi-plus',
            copy: 'mdi-content-copy',
            selected: 'mdi-check',
            curve: 'crv-curve'
        }
    },
    theme: {
        options: {customProperties: true},
    },
    lang : {
        locales: {hu},
        current: 'hu'
    }
});
