<template>
    <div>
        <v-divider class="pt-4" v-if="!first"/>
        <v-row dense class="mx-2">
            <v-col>
                <v-text-field label="Név" v-model="type.name" :rules="rules.name" dense ref="name"/>
            </v-col>
            <v-col cols="auto">
                <v-btn icon class="mx-1" @click="$emit('delete')">
                    <v-icon color="error">
                        $delete
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import VuetifyJetValidator from "vuetify-jet-validator";

const validator = new VuetifyJetValidator();

export default {
    name: "pocket-type",
    props: {
        value: {type: Object, required: true},
        first: Boolean,
        nonUniqueNames: Set
    },

    data() {
        return {
            rules: {
                name: [
                    validator.rules.required("Név megadása kötelező"),
                    v => !this.nonUniqueNames.has(v) || "Nem egyedi típus név"
                ]
            }
        };
    },

    computed: {
        type: {
            get() {
                return this.value;
            },
            set(mapping) {
                this.$emit('input', mapping);
            }
        }
    },

    methods: {
        focus() {
            this.$refs.name.focus();
        },
    },

    watch: {
        nonUniqueNames() {
            this.$refs.name.validate();
        }
    }
};
</script>
