export default {
    namespaced: true,

    state: {
        firebaseUser: null
    },

    getters: {
        isLoggedIn: state => state.firebaseUser != null,
        displayName: state => state.firebaseUser?.displayName,
        firebaseUser: state => state.firebaseUser
    },

    mutations: {
        setUser(state, user) {
            state.firebaseUser = user;
        }
    },

    actions: {
        setUser({commit}, user) {
            commit('setUser', user);
        }
    }
};
