import Login from "./Login";
import AuthView from "@/auth/AuthView";

export default [
    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/users',
        component: AuthView
    }
];
