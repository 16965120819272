<template>
    <div>
        <app-bar-menu>
            <template #default="{color}">
                <span :class="[`${color}--text`, 'headline-2']">Rögzítés</span>
                <v-spacer/>
                <v-btn icon @click="save">
                    <v-icon :color="color">mdi-send</v-icon>
                </v-btn>
            </template>
        </app-bar-menu>

        <v-form ref="form">
            <v-card flat>
                <v-card-text>
                    <cost-edit-panel ref="costPanel" @saved="reinitializeCost"></cost-edit-panel>
                </v-card-text>
            </v-card>
        </v-form>
    </div>
</template>

<script>
import AppBarMenu from "../utils/menu/AppBarMenu";
import PlatformInfoMixin from "@/utils/platform/PlatformInfoMixin";
import AuthenticationMixin from "@/auth/AuthenticationMixin";
import CostEditPanel from "@/cost/components/CostEditPanel";

export default {
    name: "mobile-create-cost",
    components: {CostEditPanel, AppBarMenu},
    mixins: [PlatformInfoMixin, AuthenticationMixin],

    data:() => ({}),

    methods: {
        save() {
            this.$refs.costPanel.save();
        },

        reinitializeCost() {
            this.$refs.costPanel.reinitializeCost();
        },
    },
};
</script>
