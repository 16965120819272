<template>
    <v-form ref="form">
        <v-btn color="primary" fab dark small right absolute class="mt-n2 mr-n2" @click="createCardNumber">
            <v-icon>$create</v-icon>
        </v-btn>

        <div v-if="!cardNumbers.length" class="text-center">Nincs kártya azonosító megadva</div>
        <scrollable-div bottom-padding="200" class="py-8" ref="container">
            <div v-for="(card, idx) in cardNumbers" :key="idx" class="pb-1">
                <v-divider class="pt-4" v-if="idx !== 0"/>
                <v-row no-gutters class="mx-4">
                    <v-col>
                        <v-text-field label="Utolsó 4 szám" v-model="card.number" v-mask="mask" :rules="rules.number"
                                      dense ref="cardNumber"/>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn color="red" icon small class="mt-2 mx-4" @click="deleteCardNumber(idx)">
                            <v-icon>$delete</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </scrollable-div>
    </v-form>
</template>

<script>
import ScrollableDiv from "@/utils/ScrollableDiv/ScrollableDiv";
import VuetifyJetValidator from "vuetify-jet-validator";
import {mask} from 'vue-the-mask';

const validator = new VuetifyJetValidator();

export default {
    name: "pocket-card-numbers",
    directives: {mask},
    components: {ScrollableDiv},
    props: {
        value: Array,
        otherCardNumbers: Array
    },

    data() {
        return {
            cardNumbers: [],
            mask: "####",
            rules: {
                number: [
                    validator.rules.required("Szám megadása kötelező"),
                    v => /^[0-9]{4}$/.test(v) || '4 számjegy megadása kötelező',
                    v => this.cardNumbers.filter(n => n.number === v).length === 1 || 'Duplikált kártyaszám',
                    this.globalUnique
                ],
            }
        };
    },

    methods: {
        createCardNumber() {
            this.cardNumbers.push({number: null});
            this.$nextTick(function () {
                this.$refs.cardNumber[this.cardNumbers.length - 1].focus();
                this.$refs.container.goToEnd();
            });
        },

        deleteCardNumber(idx) {
            this.cardNumbers.splice(idx, 1);
        },

        globalUnique(cardNumber) {
            let existingCardNumbers = this.otherCardNumbers.filter(other => other.number === cardNumber);
            return existingCardNumbers.length === 0 || 'A kártyaszám a ' + existingCardNumbers[0].pocket + ' zsebhez már hozzá lett rendelve';
        },

        save() {
            if (!this.$refs.form.validate()) {
                return;
            }

            this.$emit('input', this.cardNumbers.map(it => it.number));
            this.$emit('saved');
        },
    },
    watch: {
        cardNumbers: {
            // minden mezőnél ellenőrizze a duplikációt
            handler() {
                this.$nextTick(function () {
                    this.$refs.form.validate();
                });
            },
            deep: true
        }
    },

    mounted() {
        this.cardNumbers = this.value.map(it => ({number: it}));
        if (!this.cardNumbers.length) {
            this.createCardNumber();
        }
    }
};
</script>
