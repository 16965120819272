<template>
    <v-dialog v-if="shown" v-model="shown" :max-width="message.dialogMaxWidth || 500" persistent>
        <v-card>
            <v-responsive>
                <v-card-title v-if="message.title"
                              class="primary white--text text-subtitle-1 text-lg-h5 text-xl-h5 py-2">
                    {{ message.title }}
                </v-card-title>
                <v-card-text class="pt-6">
                    {{ message.text }}
                    <v-text-field ref="prompt" v-if="type === 'prompt'" :type="message.type || 'text'"
                                  v-model="user_input" @keydown="checkSubmit"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click.native="cancel" v-shortkey.once="['esc']" @shortkey="cancel"
                           v-if="type !== 'alert'">{{ message.cancelText }}
                    </v-btn>
                    <v-btn @click="accept" v-shortkey.once="['enter']" @shortkey="accept()" color="primary">
                        {{ message.acceptText }}
                    </v-btn>
                </v-card-actions>
            </v-responsive>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: "simple-dialog",
    data() {
        return {
            type: null,
            message: {
                dialogMaxWidth: null,
                title: null,
                text: null,
                cancelText: null,
                acceptText: null
            },
            resolve: null,
            shown: false,
            user_input: ""
        };
    },
    methods: {
        showMessage(message, type, resolve) {
            this.shown = true;
            this.type = type;
            this.message = Object.assign(this.message, message);
            this.resolve = resolve;
            this.user_input = "";

            this.$root.$children[0].addBackButtonListener(this.cancel);

            if (this.type === "prompt") {
                setTimeout(function () {
                    this.$refs.prompt.focus();
                }.bind(this), 300);
            }
        },
        cancel() {
            this.$root.$children[0].removeLastBackButtonListener();

            this.shown = false;
            this.resolve(false);
        },
        accept() {
            this.$root.$children[0].removeLastBackButtonListener();

            this.shown = false;
            this.resolve(this.type === "prompt" ? this.user_input : true);
        },
        checkSubmit(evt) {
            if (evt.key === "Enter") {
                this.accept();
            }
        }
    }
};
</script>
