<template>
    <v-container fluid>
        <v-row>
            <v-col cols="auto" v-if="isAdmin">
                <group-list/>
            </v-col>
            <v-col cols="auto">
                <user-list/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import GroupList from "@/auth/components/GroupList";
import UserList from "@/auth/components/UserList";

export default {
    name: "auth-view",
    components: {UserList, GroupList},
    data: () => ({
        isAdmin: false
    }),

    created() {
        this.$api.users.getCurrentUser()
            .then(user => this.isAdmin = user.admin);
    }
};
</script>
