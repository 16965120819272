<template>
    <v-form ref="form">
        <v-container fluid>
            <v-row no-gutters>
                <v-col>
                    <v-text-field label="Név*" v-model="mainCategory.name" :rules="rules.name" class="pr-4" dense/>
                </v-col>
                <v-col cols="auto" class="mt-n2">
                    <icon-picker v-model="mainCategory.icon" :color.sync="mainCategory.color"/>
                </v-col>
            </v-row>
            <v-switch label="Statisztikában látszódik" v-model="mainCategory.autoinclude" class="mt-0"
                      hint="A kikapcsolt fő kategóriák és a hozzátartozó kategóriák a statisztika diagramjaiban nem jelennek meg"
                      persistent-hint/>
            <v-divider class="my-3"/>
            <v-row no-gutters>
                <v-col class="subtitle-1 pt-2">
                    Kategóriák
                </v-col>
                <v-col cols="auto">
                    <search-field v-model="search"/>
                </v-col>
            </v-row>
            <simple-table :items="categories" :headers="headers" :bottom-padding="300">
                <template #item.selected="{ item }">
                    <v-checkbox v-model="item.selected" :disabled="item.disabled" hide-details class="pb-4"/>
                </template>
                <template #item.name="{ item }">
                    <name-with-icon :value="item"/>
                </template>
            </simple-table>
        </v-container>
    </v-form>
</template>

<script>

import VuetifyJetValidator from "vuetify-jet-validator";
import SearchField from "@/utils/search/SearchField";
import SimpleTable from "@/utils/table/SimpleTable";
import NameWithIcon from "@/utils/icons/NameWithIcon";
import IconPicker from "@/utils/IconPicker";

const validator = new VuetifyJetValidator();

export default {
    name: "main-category-form",
    components: {NameWithIcon, IconPicker, SimpleTable, SearchField},
    props: {
        id: [String, Number]
    },

    data: () => ({
        mainCategory: {
            id: null,
            name: null,
            icon: null,
            color: null,
            autoinclude: false,
            categoryIds: []
        },
        rules: {
            name: [validator.rules.required("Név megadása kötelező")],
        },
        headers: [
            {text: 'Tartózik', value: 'selected', width: 80, sortable: false},
            {text: 'Név', value: 'name'}
        ],
        search: null,
        categories: [],
    }),

    methods: {
        save() {
            if (!validator.formIsValid(this.$refs.form)) {
                return;
            }

            let selectedCategoryIds = this.categories.filter(it => it.selected).map(it => it.id);
            this.$api.mainCategories.saveMainCategory(Object.assign(this.mainCategory, {categoryIds: selectedCategoryIds}))
                .then(() => this.$success('Sikeres mentés'))
                .then(() => this.$emit('saved'));
        },

        fillOriginallySelectedCategory(category, originallyContainedByThis = false) {
            return Object.assign(category, {disabled: originallyContainedByThis, selected: originallyContainedByThis});
        },

        setOriginallySelectedCategories() {
            this.categories
                .filter(it => this.mainCategory.categoryIds.indexOf(it.id) !== -1)
                .forEach(it => this.fillOriginallySelectedCategory(it, true));
        }
    },

    mounted() {
        this.$api.categories.getCategories()
            .then(cats => this.categories = cats.map(it => this.fillOriginallySelectedCategory(it)))
            .then(() => {
                if (this.id) {
                    this.$api.mainCategories.getMainCategory(this.id)
                        .then(category => this.mainCategory = category)
                        .then(() => this.setOriginallySelectedCategories());
                }
            });
    }
};
</script>
