<template>
    <div>
        <v-container fluid>
            <v-row>

                <app-bar-menu v-if="isOnAndroid" v-touch="{down: () => refreshPage()}">
                    <template #default="{ color }">
                        <span :class="[`${color}--text`, 'headline-2']">Egyenleg</span>
                        <v-spacer/>
                        <v-checkbox v-model="average" class="mt-5" color="primary">
                            <template #label>
                                <div class="primary--text">Átlag számítás</div>
                            </template>
                        </v-checkbox>
                        <month-filter-picker v-model="monthRange" :color="color"/>
                    </template>
                </app-bar-menu>

                <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3" v-touch="{down: () => refreshPage()}">
                    <v-card v-if="!isOnAndroid">
                        <v-card-text>
                            <v-row dense>
                                <v-col>
                                    <month-filter-picker v-model="monthRange"/>
                                </v-col>
                                <v-col class="mt-n3">
                                    <v-row no-gutters class="justify-end">
                                        <v-checkbox label="Átlag számítás" v-model="average"/>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <div :class="isOnAndroid ? 'mt-n3' : 'mb-6'"/>

                    <cost-summary ref="summary">
                        <template #middle="{valueClass}" v-if="isOnAndroid">
                            <div :class="['text-center', 'mt-n4', valueClass]">
                                {{ monthRange | asHunDate }}
                            </div>
                        </template>
                        <template #default="{ summary, labelClass, valueClass }">
                            <div :class="labelClass">Maradék</div>
                            <div :class="[valueClass, 'mb-n8']">{{ summary.difference | asCurrency }}</div>
                            <v-fab-transition v-if="!isOnAndroid">
                                <v-btn color="primary" dark fab right small class="mb-n16 mr-n9" @click="refreshPage">
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </v-fab-transition>
                        </template>
                    </cost-summary>
                </v-col>

                <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3">
                    <v-card :flat="isOnAndroid" :class="{'mt-n3': isOnAndroid}">
                        <v-card-title class="text-h5" v-if="!isOnAndroid">
                            <v-row dense>
                                <information title="Kategóriák">
                                    <ul>
                                        <li>Azon kategóriáknál, ahol be lett korlát állítva megjelenik a korláthoz viszonyított arány</li>
                                        <li>A váratlan kiadások az összeg alatt találhatóak, az <v-icon small color="error">mdi-alert-outline</v-icon> ikonnal megjelölve</li>
                                    </ul>
                                </information>
                            </v-row>
                            <v-row dense class="justify-end">
                                <v-col cols="auto">
                                    <search-field v-model="categoryStat.search" style="width: 200px;"/>
                                </v-col>
                            </v-row>
                        </v-card-title>

                        <v-card-text :class="{'pa-0': isOnAndroid}">
                            <virtual-scroll-table v-bind="categoryStat" store-name="Dashboard"
                                                  :bottom-padding="isOnAndroid ? 72 : 28">
                                <template #item="{item, itemHeight}">
                                    <category-summary :value="item" :height="itemHeight"/>
                                </template>
                            </virtual-scroll-table>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6" v-if="!isOnAndroid">
                    <categories-diagram ref="diagram"/>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import {thisMonth, toHunDate, toPath} from "@/utils/DatePicker/DateUtils";
import MonthFilterPicker from "@/utils/DatePicker/MonthFilterPicker";
import CostSummary from "@/cost/components/CostSummary";
import SearchField from "@/utils/search/SearchField";
import CategoriesDiagram from "@/statistics/components/CategoriesDiagram";

import {toCurrency} from "@/utils/CurrencyField/CurrencyUtils";
import VirtualScrollTable from "@/utils/table/VirtualScrollTable";
import PlatformInfoMixin from "@/utils/platform/PlatformInfoMixin";
import AppBarMenu from "@/utils/menu/AppBarMenu";
import CategorySummary from "@/statistics/CategorySummary";
import Information from "@/utils/information/Information";

export default {
    name: "dashboard",
    mixins: [PlatformInfoMixin],
    components: {
        Information,
        CategorySummary,
        AppBarMenu, VirtualScrollTable, CategoriesDiagram, SearchField, CostSummary, MonthFilterPicker},
    data() {
        return {
            monthRange: thisMonth(),
            average: false,
            categoryStat: {
                items: [],
                headers: [
                    {text: "Kategória", value: "name"},
                    {text: "Korlát", align: "center", sortable: false, value: "limit"},
                    {text: "Összeg", align: "right", value: "cost"}
                ],
                search: null,
                loading: false,
                'item-height': 46,
            },
        };
    },

    methods: {
        refreshPage() {
            this.categoryStat.loading = true;
            let calls = [
                this.$refs.summary.refreshSummary(this.monthRange, "", this.average),
                this.$api.categories.getMonthlyStatistics(toPath(this.monthRange), this.average)
                    .then(stat => this.categoryStat.items = stat),
            ];
            if (!this.isOnAndroid) {
                calls.push(this.$refs.diagram.reloadDiagram(this.monthRange, this.average));
            }
            Promise.all(calls)
                .then(() => this.categoryStat.loading = false);
        },
    },

    watch: {
        average() {
            this.refreshPage();
        },

        monthRange() {
            this.refreshPage();
        },
    },

    filters: {
        asHunDate: value => toHunDate(value),
        asCurrency: value => toCurrency(value)
    },

    mounted() {
        this.refreshPage();
    }
};
</script>
