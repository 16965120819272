<template>
    <v-data-table hide-default-footer disable-pagination :headers="headers" :items="items" :loading="loading"
                  v-on="$listeners" :show-select="showSelect" :search="search" :value="value"
                  :options.sync="options" ref="table">
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope"/>
        </template>
        <template #body="{ items, isSelected, select }" v-if="items.length > 0">
            <tbody v-resize="resizeTableMaxHeight" ref="tableBody">
            <td :colspan="headers.length + 1" class="pt-1">
                <v-virtual-scroll :items="items" :item-height="correctedItemHeight" :bench="isOnAndroid ? 1 : 5"
                                  :max-height="maxHeight" :key="refreshForcer">
                    <template v-slot="{ item, index }">
                        <row :index="index" :height="itemHeight" :last="index === items.length - 1" ref="itemRow">
                            <slot name="item" v-bind="{ item, index, isSelected, select, itemHeight }"></slot>
                        </row>
                    </template>
                </v-virtual-scroll>
            </td>
            </tbody>
        </template>
    </v-data-table>
</template>

<script>
import Row from "@/utils/table/Row";
import PlatformInfoMixin from "@/utils/platform/PlatformInfoMixin";

export default {
    name: "virtual-scroll-table",
    mixins: [PlatformInfoMixin],
    components: {Row},
    props: {
        itemHeight: {
            type: [Number, String],
            required: true,
        },
        loading: Boolean,
        showSelect: Boolean,
        headers: Array,
        items: Array,
        search: String,
        value: Array,
        bottomPadding: {
            type: [Number, String],
            default: 8,
        },
        storeName: String
    },

    data: () => ({
        maxHeight: 3000,
        refreshForcer: 0,
        options: {}
    }),

    computed: {
        correctedItemHeight() {
            return this.isOnAndroid ? this.itemHeight + 4 : this.itemHeight;
        }
    },

    watch: {
        items() {
            this.resizeTableMaxHeight();
            this.refreshForcer++;
        },
        bottomPadding() {
            this.resizeTableMaxHeight();
        },
        options: {
            handler(newValue) {
                if (this.storeName) {
                    localStorage.setItem(this.storeName, JSON.stringify(newValue));
                }
            },
            deep: true
        }
    },

    methods: {
        resizeTableMaxHeight() {
            this.$nextTick(function () {
                const tableWrapper = this.$refs.table.$el;
                const tableIndex = tableWrapper.childNodes[0].className.includes('v-data-table__wrapper') ? 0 : 1;
                const footerHeight = tableWrapper.childNodes.length > tableIndex + 1 ? tableWrapper.childNodes[tableIndex + 1].getBoundingClientRect().height : 0;
                if (this.$refs["tableBody"]) {
                    this.maxHeight = window.innerHeight - this.$refs.tableBody.getBoundingClientRect().top - this.bottomPadding - footerHeight;
                }
            });
        },
    },

    created() {
        if (this.storeName) {
            let stored = localStorage.getItem(this.storeName);
            if (stored) {
                this.options = Object.assign({}, this.options, JSON.parse(stored));
            }
        }
    },

    mounted() {
        this.resizeTableMaxHeight();
    }
};
</script>
