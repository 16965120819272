var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('simple-crud-list',_vm._b({on:{"save":_vm.save},scopedSlots:_vm._u([{key:"form",fn:function(ref){
var id = ref.id;
var on = ref.on;
return [_c('main-category-form',_vm._g({ref:"form",attrs:{"id":id}},on))]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('name-with-icon',{attrs:{"value":item}})]}},{key:"item.autoinclude",fn:function(ref){
var value = ref.value;
return [_c('boolean-icon',{attrs:{"value":value}})]}}])},'simple-crud-list',_vm.options,false))}
var staticRenderFns = []

export { render, staticRenderFns }