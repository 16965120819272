<template>
    <v-form ref="form">
        <v-container fluid>
            <v-row no-gutters>
                <v-col cols="12">
                    <date-picker v-model="cost.date" label="Dátum*"/>
                </v-col>
                <v-col cols="12">
                    <currency-field ref="cost" v-model="cost.cost" label="Költség*" :rules="rules.cost"
                                    autofocus/>
                </v-col>
                <v-col cols="12" v-if="pockets.length > 0">
                    <name-with-icon-select v-model="cost.sourcePocket" label="Zseb" :items="pockets" clearable/>
                </v-col>
                <v-col cols="12">
                    <name-with-icon-select v-model="cost.category" label="Kategória*" :items="categories"
                                           :rules="rules.category" auto-select-first/>
                </v-col>
                <v-col cols="12" class="mt-n3 mb-n1">
                    <v-switch label="Váratlan" v-model="cost.unexpected" dense/>
                </v-col>
                <v-col cols="12">
                    <v-text-field v-model="cost.description" label="Megjegyzés" hide-details/>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import VuetifyJetValidator from "vuetify-jet-validator";
import CurrencyField from "@/utils/CurrencyField";
import NameWithIconSelect from "@/utils/icons/NameWithIconSelect";
import DatePicker from "@/utils/DatePicker/DatePicker";
import PlatformInfoMixin from "@/utils/platform/PlatformInfoMixin";
import AuthenticationMixin from "@/auth/AuthenticationMixin";
import {today} from "@/utils/DatePicker/DateUtils";

const validator = new VuetifyJetValidator();

export default {
    name: "cost-edit-panel",
    components: {NameWithIconSelect, CurrencyField, DatePicker},
    mixins: [PlatformInfoMixin, AuthenticationMixin],
    props: {
        id: Number,
        moveFrom: Number,
    },

    data() {
        return {
            cost: {
                id: null,
                date: today(),
                cost: 0,
                category: null,
                unexpected: false,
                description: null,
                sourcePocket: null
            },

            rules: {
                date: [validator.rules.required("Dátum megadása kötelező")],
                category: [validator.rules.required("Kategória megadása kötelező")],
                cost: [
                    validator.rules.required("Költség megadása kötelező"),
                    value => value > 0 || 'A mező csak 0-nál nagyobb számot tartalmazhat',
                    value => (this.moveFrom == null || value < this.moveFrom) || `Kisebbnek kell lennie mint az eredeti érték (${this.moveFrom})`
                ],
            },

            categories: [],
            pockets: []
        };
    },

    methods: {
        save() {
            if (!validator.formIsValid(this.$refs.form)) {
                return;
            }
            (this.moveFrom ? this.$api.costs.refactorCost(this.cost.id, this.cost) : this.$api.costs.saveCost(this.cost))
                .then(() => this.$success('Sikeres mentés'))
                .then(() => this.$emit('saved'));
        },

        reinitializeCost() {
            Object.assign(this.cost, {cost: 0, description: null});
            this.$refs.cost.focus();
        },

        focusToCost() {
            this.$nextTick(function () {
                this.$refs.cost.focus();
            });
        },

        initializePocket() {
            if (!this.id && this.pockets.length > 0) {
                this.cost.sourcePocket = this.pockets[0].id;
            }
        },

        loadWhenSignedIn() {
            if (this.id) {
                this.$api.costs.getCost(this.id)
                    .then(cost => Object.assign(this.cost, cost, {cost: this.moveFrom ? 0 : cost.cost, description: this.moveFrom ? null : cost.description}))
                    .then(() => this.focusToCost());
            }
            this.$api.categories.getCategories(true).then(categories => this.categories = categories);
            this.$api.pockets.getPocketsOnly()
                .then(pockets => this.pockets = pockets)
                .then(() => this.initializePocket());
        }
    },
};
</script>
