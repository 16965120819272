export default ctx => ({
    users: {
        getUsers() {
            return ctx.$axios.get("/user").then(response => response.data);
        },
        getUser(id) {
            return ctx.$axios.get("/user/" + id).then(response => response.data);
        },
        saveUser(user) {
            return ctx.$axios.put("/user", user);
        },
        deleteUser(id) {
            return ctx.$axios.delete("/user/" + id);
        },
        getCurrentUser() {
            return ctx.$axios.get("user/current").then(response => response.data);
        },
    }
});
