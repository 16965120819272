<template>
    <v-dialog ref="dialog" v-model="menu" persistent width="450px">
        <template v-slot:activator="{ on }">
            <v-btn large icon outlined :color="color" class="elevation-3" v-on="on">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on : tt }">
                        <v-icon :color="color" v-on="tt">{{ value }}</v-icon>
                    </template>
                    {{ value ? value.replace('mdi-', '') : 'Nincs ikon kiválasztva' }}
                </v-tooltip>
            </v-btn>
        </template>
        <icon-picker-dialog :value="value" @input="save($event)" :color="color"
                            @update:color="$emit('update:color', $event)" v-if="menu" @cancel="menu = false">
        </icon-picker-dialog>
    </v-dialog>
</template>
<script>

import IconPickerDialog from "./IconPickerDialog";
import {DEFAULT_COLOR} from "@/category/colors";

export default {
    name: "icon-picker",
    components: {IconPickerDialog},
    props: {
        value: String,
        color: {String, default: DEFAULT_COLOR}
    },

    data: () => ({
        menu: false,
    }),

    methods: {
        save(icon) {
            this.$emit('input', icon);
            this.menu = false;
        }
    },
};
</script>
