<template>
    <div>
        <v-select v-if="isMobile" v-bind="options" v-on="$listeners" :menu-props="menuAdjust">
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
                <slot :name="slot" v-bind="scope"/>
            </template>
        </v-select>
        <v-autocomplete v-else v-bind="options" v-on="$listeners">
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
                <slot :name="slot" v-bind="scope"/>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
import PlatformInfoMixin from "@/utils/platform/PlatformInfoMixin";

export default {
    name: "cm-select",
    mixins: [PlatformInfoMixin],
    props: {
        value: Number,
        label: String,
        items: Array,
        rules: Array,
        disabled: Boolean,
        autoSelectFirst: Boolean,
        dense: Boolean,
        itemText: String,
        itemValue: String,
        clearable: Boolean
    },

    data: () => ({}),

    computed: {
        options() {
            return ({
                value: this.value,
                label: this.label,
                items: this.items,
                rules: this.rules,
                disabled: this.disabled,
                autoSelectFirst: this.autoSelectFirst,
                dense: this.dense,
                itemText: this.itemText,
                itemValue: this.itemValue,
                clearable: this.clearable
            });
        },

        menuAdjust() {
            return ({'overflowY': true, 'maxHeight': '90%'});
        }
    },

    methods: {
        selectFirstItem() {
            if (this.autoSelectFirst && this.value == null && (this.items || []).length > 0) {
                this.$emit('input', this.items[0].id);
            }
        }
    },

    watch: {
        autoSelectFirst: {
            handler: "selectFirstItem",
            immediate: true
        },
        items: {
            handler: "selectFirstItem",
            immediate: true
        },
    }
};
</script>
