<template>
    <v-dialog v-model="visible" v-if="visible" :max-width="maxWidth" persistent :fullscreen="isOnAndroid">
        <v-toolbar v-if="isOnAndroid">
            <v-btn icon @click="closeFormDialog()" color="primary">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <span class="primary--text headline-2">{{ title }}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="saveFormDialog" color="primary">
                <v-icon>mdi-check</v-icon>
            </v-btn>
        </v-toolbar>

        <v-card>
            <v-card-title class="primary white--text" v-if="!isOnAndroid">
                {{ title }}
                <slot name="title"/>
            </v-card-title>
            <v-card-text :class="{'pt-4': !isOnAndroid}">
                <slot name="default" v-bind="{on: {saved: () => closeFormDialog(true)}}"></slot>
            </v-card-text>
            <v-card-actions v-if="!isOnAndroid">
                <v-spacer/>
                <cancel-button @click="closeFormDialog()">Mégsem</cancel-button>
                <ok-button @click="saveFormDialog">Mentés</ok-button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import CancelButton from "@/utils/form/CancelButton";
import OkButton from "@/utils/form/OkButton";
import PlatformInfoMixin from "@/utils/platform/PlatformInfoMixin";

export default {
    name: "form-dialog",
    mixins: [PlatformInfoMixin],
    components: {OkButton, CancelButton},
    props: {
        maxWidth: {type: [Number, String], default: 500}
    },

    data: () => ({
        visible: false,
        title: String,
    }),

    methods: {
        openFormDialog(title) {
            this.title = title;
            this.visible = true;

            this.$root.$children[0].addBackButtonListener(this.closeFormDialog);

            this.$nextTick(function() {
                this.$emit('shown');
            });
        },

        closeFormDialog(refresh = false) {
            this.visible = false;

            this.$root.$children[0].removeLastBackButtonListener();

            this.$emit('close');
            if (refresh) {
                this.$emit('refresh');
            }
        },

        saveFormDialog() {
            this.$emit('save');
        }
    },
};
</script>
