export default ctx => ({
    pockets: {
        getPockets() {
            return ctx.$axios.get("/pockets").then(response => response.data);
        },
        getPocketsOnly() {
            return ctx.$axios.get("/pockets/onlyPockets").then(response => response.data);
        },
        savePockets(pockets) {
            return ctx.$axios.put("/pockets", pockets).then(response => response.data);
        },
    }
});
