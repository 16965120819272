import colors from "vuetify/lib/util/colors";

const COLORS = [
    colors.red,
    colors.pink,
    colors.purple,
    colors.deepPurple,
    colors.indigo,
    colors.blue,
    colors.lightBlue,
    colors.cyan,
    colors.teal,
    colors.green,
    colors.lightGreen,
    colors.lime,
    colors.yellow,
    colors.orange,
    colors.deepOrange,
    colors.brown,
    colors.blueGrey
];
export const CATEGORY_COLORS = ['lighten3', 'lighten1']
    .flatMap(shadow => COLORS.map(baseColor => baseColor[shadow]));
export const MAIN_CATEGORY_COLORS = ['darken1', 'darken3']
    .flatMap(shadow => COLORS.map(baseColor => baseColor[shadow]));

export const DEFAULT_COLOR = colors.grey.darken1;
