<template>
    <v-dialog ref="dialog" v-model="modal" persistent width="330px">
        <template #activator="{ on : menu }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on : tooltip }">
                    <v-btn icon v-on="{...menu, ...tooltip}" style="margin-top: -4px;background-color: white;">
                        <v-icon :color="value" >mdi-brush</v-icon>
                    </v-btn>
                </template>
                Színválasztó
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title class="pa-0">
                <v-toolbar color="primary" dark flat>
                    <v-toolbar-title>Színválasztó</v-toolbar-title>
                </v-toolbar>
            </v-card-title>
            <v-card-text>
                <v-row no-gutters justify="center">
                    <v-color-picker hide-canvas hide-inputs hide-mode-switch show-swatches v-model="color"/>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" text @click="resetColor">Törlés</v-btn>
                <v-spacer></v-spacer>
                <cancel-button @click="modal = false">Mégsem</cancel-button>
                <ok-button @click="save">Kiválaszt</ok-button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import CancelButton from "@/utils/form/CancelButton";
import OkButton from "@/utils/form/OkButton";
import {DEFAULT_COLOR} from "@/category/colors";

export default {
    name: "color-picker",
    components: {OkButton, CancelButton},
    props: {
        value: String
    },

    data() {
        return {
            modal: false,
            color: null
        };
    },

    methods: {
        save() {
            this.$emit('input', typeof this.color === 'object' ? this.color.hexa : this.color);
            this.modal = false;
        },
        resetColor() {
            this.color = DEFAULT_COLOR;
        }
    },

    watch: {
        value() {
            this.color = this.value;
        }
    },
};
</script>
