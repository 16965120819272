export default ctx => ({
    categories: {
        getCategories(byOrder = false) {
            return ctx.$axios.get(`/category?byOrder=${byOrder}`)
                .then(response => response.data);
        },
        getCategory(id) {
            return ctx.$axios.get("/category/" + id)
                .then(response => response.data);
        },
        saveCategory(category) {
            return ctx.$axios.put("/category", category);
        },
        deleteCategory(id) {
            return ctx.$axios.delete("/category/" + id);
        },
        getMonthlyStatistics(monthPath, average = false, search = "") {
            return ctx.$axios.get(`/category/statistic/monthly/${monthPath}?search=${search ? search : ''}&average=${average}`)
                .then(response => response.data);
        },
        getExpenseStatistics(monthPath, average = false) {
            return ctx.$axios.get(`/category/statistic/expense/${monthPath}?average=${average}`)
                .then(response => response.data);
        },
        getTimelineStatistics(monthPath, period, search = "") {
            return ctx.$axios.get(`/category/statistic/${period}/timeline/${monthPath}?search=${search ?? ''}`)
                .then(response => response.data);
        },
    }
});
