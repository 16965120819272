import {Plugins} from '@capacitor/core';

const {Device} = Plugins;

export default {
    namespaced: true,

    state: {
        platform: null,
        operatingSystem: null,
        platformLoaded: false
    },

    getters: {
        isWeb: state => state.platform == 'web',
        isOnAndroid: state => state.operatingSystem == 'android',
        isWebOnAndroid: state => state.platform == 'web' && state.operatingSystem == 'android',
        isAndroidApp: state => state.platform == 'android',
        isPlatformLoaded: state => state.platformLoaded
    },

    mutations: {
        change_platform(state, platform) {
            state.platform = platform.platform;
            state.operatingSystem = platform.operatingSystem;
            state.platformLoaded = true;
        }
    },

    actions: {
        getInfo({commit}) {
            return Device.getInfo().then(info => {
                commit('change_platform', info);
            });
        }
    }
};
