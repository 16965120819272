<script>

import _ from "lodash";
import SearchField from "@/utils/search/SearchField";

export default {
    name: "debounced-search-field",
    extends: SearchField,

    methods: {
        localValueChanged() {
            this.debouncedSearch();
        },
        debouncedSearch: _.debounce(function () {
            this.$emit('input', this.localValue);
        }, 400)
    },
};
</script>
