<template>
    <v-form ref="form">
        <v-container fluid>
            <div class="caption mb-n5">Típus*</div>
            <v-radio-group v-model="category.type" row :rules="rules.type">
                <v-radio label="Bevétel" value="INCOME"/>
                <v-radio label="Kiadás" value="EXPENSE"/>
                <v-radio label="Megtakarítás" value="SAVE"/>
            </v-radio-group>
            <v-row no-gutters>
                <v-col>
                    <v-text-field label="Név*" v-model="category.name" :rules="rules.name" class="pr-4"/>
                </v-col>
                <v-col cols="auto" class="mt-2">
                    <icon-picker v-model="category.icon" :color.sync="category.color"/>
                </v-col>
            </v-row>
            <v-text-field label="Sorrend*" v-model.number="category.order" type="number" :rules="rules.order"
                          hint="A kategóriák megjelenítése rögzítéskor ezen mező csökkenő sorrendjében, azon belül abc sorrendben történik."
                          persistent-hint/>
            <currency-field label="Havi korlát*" v-model="category.monthlyLimit" :rules="rules.monthlyLimit"/>
            <v-autocomplete label="Főkategória*" :items="mainCategories" v-model="category.mainCategoryId"
                            item-text="name" item-value="id" :rules="rules.mainCategoryId"/>
        </v-container>
    </v-form>
</template>

<script>

import VuetifyJetValidator from "vuetify-jet-validator";
import CurrencyField from "@/utils/CurrencyField/CurrencyField";
import IconPicker from "@/utils/IconPicker/index";

const validator = new VuetifyJetValidator();

export default {
    name: "category-form",
    components: {IconPicker, CurrencyField},
    props: {
        id: [String, Number]
    },

    data: () => ({
        category: {
            id: null,
            type: null,
            name: null,
            order: 0,
            monthlyLimit: 0,
            mainCategoryId: null,
            icon: null,
            color: null
        },
        rules: {
            type: [validator.rules.required("Típus megadása kötelező")],
            name: [validator.rules.required("Név megadása kötelező")],
            order: [
                value => /^-?[0-9]+$/.test(value) || "A sorrend csak számot tartalmazhat"
            ],
            monthlyLimit: [value => /^[0-9]+$/.test(value) || "A havi korlát csak számot tartalmazhat"],
            mainCategoryId: [validator.rules.required("Főkategória megadása kötelező")],
        },
        mainCategories: [],
    }),

    methods: {
        save() {
            if (!validator.formIsValid(this.$refs.form)) {
                return;
            }
            this.$api.categories.saveCategory(this.category)
                .then(() => this.$success('Sikeres mentés'))
                .then(() => this.$emit('saved'));
        },
    },

    mounted() {
        this.$api.mainCategories.getMainCategories().then(cats => this.mainCategories = cats);

        if (this.id) {
            this.$api.categories.getCategory(this.id).then(category => this.category = category);
        } else {
            this.category.type = "EXPENSE";
        }
    }
};
</script>
