<template>
    <v-container fluid>
        <v-row>
            <v-col cols="auto">
                <main-category-list/>
            </v-col>
            <v-col cols="auto">
                <category-list/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import MainCategoryList from "@/category/components/MainCategoryList";
import CategoryList from "@/category/components/CategoryList";

export default {
    name: "categories-view",
    components: {CategoryList, MainCategoryList}
};
</script>
