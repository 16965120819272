import {registerWebPlugin, WebPlugin} from "@capacitor/core";

export class CurveWeb extends WebPlugin {
    constructor() {
        super({
            name: 'Curve',
            platforms: ['web']
        });
    }

    async getReceivedSendIntent() {
        return {text: null};
    }
}

const Curve = new CurveWeb();
export {Curve};

registerWebPlugin(Curve);
