<template>
    <v-app>

        <app-bar-menu>
            <v-spacer/>
            {{ version }}
        </app-bar-menu>

        <alert ref="snackbar"/>
        <simple-dialog ref="simpleDialog"/>

        <app-menu/>

        <v-main>
            <router-view/>
        </v-main>
    </v-app>
</template>

<script>
import AppMenu from "./utils/menu/AppMenu";

import {mapActions} from 'vuex';
import PlatformInfoMixin from "./utils/platform/PlatformInfoMixin";

import {Plugins} from '@capacitor/core';
import './plugins/firebase';
import AppBarMenu from "./utils/menu/AppBarMenu";
import AuthenticationMixin from "@/auth/AuthenticationMixin";
import Alert from "@/utils/alert/Alert";
import SimpleDialog from "@/utils/dialog/SimpleDialog";
import {version} from "../package.json";

const {App, CurvePlugin, SplashScreen} = Plugins;

export default {
    name: 'App',
    mixins: [PlatformInfoMixin, AuthenticationMixin],

    components: {
        SimpleDialog,
        Alert,
        AppBarMenu,
        AppMenu
    },

    data: () => ({
        backButtonListeners: [],
        version: version
    }),

    methods: {
        ...mapActions('Platform', ['getInfo']),
        ...mapActions('Authentication', ['setUser']),

        handleServerError(error) {
            const status = error.status || error?.response?.status;
            if (status === 401) {
                this.$error('Lejárt a belépés ideje; jelentkezzen be újra');
            } else if (status === 403) {
                this.$error('Nincs jogosultsága a funkció végrehajtásához');
            } else if (error.response) {
                this.$error(error.response.data);
            } else {
                this.$error(error.message);
            }
            return Promise.reject();
        },

        handleBackButton() {
            if (this.backButtonListeners.length > 0) {
                this.backButtonListeners[this.backButtonListeners.length - 1]();
            } else {
                App.exitApp();
            }
        },
        addBackButtonListener(listener) {
            this.backButtonListeners.push(listener);
        },
        removeLastBackButtonListener() {
            this.backButtonListeners.pop();
        }
    },

    async created() {
        Plugins.FirebaseAuth.addListener('authChanged', user => this.setUser(user));

        this.$axios.interceptors.response.use(res => res, this.handleServerError);
        this.$axios.interceptors.request.use(
            async config => {
                let user = this.firebaseUser;
                if (user != null) {
                    let idToken = await user.getIdToken(false);
                    config.headers["Authorization"] = 'Bearer ' + idToken;
                }
                return config;
            },
            error => Promise.reject(error)
        );
    },

    watch: {
        isOnAndroid(isIt) {
            if (isIt) {
                CurvePlugin.getReceivedSendIntent().then(received => {
                    if (received.text == null) {
                        this.$router.replace('/mobile/cost');
                    } else {
                        this.$router.replace({name: 'Curve', params: {text: received.text}});
                    }
                });
            }
        },

        isPlatformLoaded: {
            handler() {
                SplashScreen.hide();
            },
            immediate: true
        }
    },

    mounted() {
        this.getInfo();

        App.addListener("backButton", this.handleBackButton);
    }
};
</script>
