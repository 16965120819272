import {mapGetters} from "vuex";

export default ({

    computed: {
        ...mapGetters('Platform', ['isWebOnAndroid', 'isAndroidApp', 'isWeb', 'isOnAndroid', 'isPlatformLoaded']),
        isMobile() {
            return this.$vuetify.breakpoint.mobile;
        },
        isDesktop() {
            return !this.$vuetify.breakpoint.mobile;
        },
        baseUri() {
            return this.isWeb ? window.location.href.replace(/\/#.*/g, '')
                : (localStorage.getItem("baseUri") || "https://nagymezei.hu/cost-mgmt");
        }
    }
});
