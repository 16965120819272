<template>
    <div>
        <router-view/>

        <v-footer app padless>
            <v-bottom-navigation color="primary">
                <v-btn to="/mobile/cost">
                    <span>Rögzítés</span>
                    <v-icon>$create</v-icon>
                </v-btn>
                <v-btn to="/mobile/costs" :disabled="!isLoggedIn">
                    <span>Rögzítettek</span>
                    <v-icon>mdi-format-list-bulleted</v-icon>
                </v-btn>
                <v-btn to="/mobile/dashboard" :disabled="!isLoggedIn">
                    <span>Egyenleg</span>
                    <v-icon>mdi-bank</v-icon>
                </v-btn>
                <v-btn to="/mobile/curve">
                    <span>Curve</span>
                    <v-icon>$curve</v-icon>
                </v-btn>
            </v-bottom-navigation>
        </v-footer>
    </div>
</template>

<script>

import AuthenticationMixin from "@/auth/AuthenticationMixin";

export default {
    name: "mobile-app",
    mixins: [AuthenticationMixin],

    data: () => ({}),
};
</script>
