function _show(color, timeout, message) {
    if (typeof message === "string") {
        message = {
            message: message
        };
    }
    const _message = {
        color: color,
        timeout: timeout
    };

    this.$root.$children[0].$refs.snackbar.showMessage(Object.assign({}, _message, message));
}

export default ({
    install(Vue) {
        Vue.prototype.$inform = function(message) {
            _show.bind(this, "info", 3000)(message);
        };
        Vue.prototype.$success = function(message) {
            _show.bind(this, "success", 3000)(message);
        };
        Vue.prototype.$warn = function(message) {
            _show.bind(this, "warn", 5000)(message);
        };
        Vue.prototype.$error = function(message) {
            _show.bind(this, "error", 5000)(message);
        };
    }
});
