export const HISTORY = {
    versions: [
        {
            version: "4.4.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: [
                        "Költségek zsebhez rendelése",
                        "Csúszka az Androidos költség listán gyorsabb",
                        "Költség mozgatáskor törlődjön a megjegyzés"
                    ]
                }
            ]
        },
        {
            version: "4.3.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: [
                        "Főkategória színe, ikonja kiválaszható",
                        "Zseb színe, ikonja kiválaszható"
                    ]
                }
            ]
        },
        {
            version: "4.2.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: [
                        "Kategória színe kiválaszható"
                    ]
                },
                {
                    section: "Technikai változás:",
                    changes: [
                        "Szerver: frissítés quarkus 1.12.1.Final-ra",
                        "Kliens: frissítés vuetify 2.4.5-re és egyéb frissítések",
                    ]
                }
            ]
        },
        {
            version: "4.1.1",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: [
                        "Az áttekintő kategória list kompaktabb lett"
                    ]
                }
            ]
        },
        {
            version: "4.1.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: [
                        "Androidos ikonok cseréje",
                        "Váratlan költségek rögzítése, megjelenítése, kereshetősége, statisztikában megjelenítés"
                    ]
                }
            ]
        },
        {
            version: "4.0.1-2",
            changes: [
                {
                    section: "Hibajavításváltozás:",
                    changes: [
                        "Androidos ikonok cseréje"
                    ]
                }
            ]
        },
        {
            version: "4.0.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: [
                        "Androidos és webes kliensek újraírása",
                        "Android: költségek szerkesztése, mozgatása (jobbra mozgatás) törlése (hosszan nyomás)",
                        "Web-es: automatikus bejelentkezés, ha nem volt korábban kijelentkezés",
                        "Analítika és összehasonlítás grafikonok átmeneti megszüntetése",
                        "Kategória eloszlás grafikonok egybegyúrása"
                    ]
                }
            ]
        },
        {
            version: "3.1.1",
            changes: [
                {
                    section: "Hibajavítás:",
                    changes: ["Statisztika szétszállás javítás"]
                }
            ]
        },
        {
            version: "3.1.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: [
                        "Kategória ikonok bevezetése"
                    ]
                },
                {
                    section: "Hibajavítás:",
                    changes: [
                        "Kategória sorrend névsorban volt költség rögzítéskor",
                        "Napi egy curve szinkronizálás tudott csak lefutni"
                    ]
                },
                {
                    section: "Technikai változás:",
                    changes: [
                        "Android: frissítés flutter 1.20.0-ra"
                    ]
                }
            ]
        },
        {
            version: "3.0.1",
            changes: [
                {
                    section: "Hibajavítás:",
                    changes: ["Szerver install csomag javítása"]
                }
            ]
        },
        {
            version: "3.0.0",
            changes: [
                {
                    section: "Technikai változás:",
                    changes: [
                        "Szerver újraírása quarkus 1.5.2-vel: visszafelé nem kompatibilis",
                        "Kliens: Frissítés vuetify 2.3.2-re",
                        "Android: frissítés flutter 1.17.5-re"
                    ]
                }
            ]
        },
        {
            version: "2.1.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: [
                        "Szerver költözés",
                        "Android kliens újraírása",
                        "Android: egyenleg frissíthetőek",
                        "Android: egyenleg hónapja választható"
                    ]
                }
            ]
        },
        {
            version: "2.0.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Zseb rögzítés és curve integrációnál automatikus töltése"]
                }
            ]
        },
        {
            version: "1.21.0",
            changes: [
                {
                    section: "Technikai változás:",
                    changes: ["Frissítés vuetify 2.1.7-re"]
                }
            ]
        },
        {
            version: "1.20.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Analítikában folyam grafikon lecserélése oszlop diagramra"]
                }
            ]
        },
        {
            version: "1.19.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Curve integráció: a költség megjegyzése alapjáni kategóriához rendelés"]
                }
            ]
        },
        {
            version: "1.18.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Curve integráció: androidos kliens, desktop kliens szépítgetés"]
                }
            ]
        },
        {
            version: "1.17.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Curve integráció: minden dátumú költség importálása", "Curve integráció androidos kliens szépítgetés"]
                }
            ]
        },
        {
            version: "1.16.4",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Curve integráció: üres megjegyzés mezőbe a vásárlás helye kerül"]
                }
            ]
        },
        {
            version: "1.16.3",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: [
                        "Importált költségek jelölése",
                        "Importált költségek nem törölhetőek",
                        "Importált költségből kimozgatott költségek importáltak lesznek"
                    ]
                }
            ]
        },
        {
            version: "1.16.2",
            changes: [
                {
                    section: "Hibajavítás:",
                    changes: ["Curve integráció szépítés, javítás android-on", "Curve kategóriák frissítése"]
                }
            ]
        },
        {
            version: "1.16.1",
            changes: [
                {
                    section: "Hibajavítás:",
                    changes: ["Fix Curve integráció android-on"]
                }
            ]
        },
        {
            version: "1.16.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Curve integráció"]
                }
            ]
        },
        {
            version: "1.15.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Több költség együttes módosítása"]
                }
            ]
        },
        {
            version: "1.14.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Több költség együttes törlése"]
                }
            ]
        },
        {
            version: "1.13.3",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Android: havi korlát megjelenítés az egyenlegen"]
                },
                {
                    section: "Hibajavítás:",
                    changes: ["Android: szervíz leállítási hiba javítása", "Android: maradék lehet negatív is az egyenlegen"]
                }
            ]
        },
        {
            version: "1.13.2",
            changes: [
                {
                    section: "Hibajavítás:",
                    changes: ["Korlát jelzése százalékkal"]
                }
            ]
        },
        {
            version: "1.13.1",
            changes: [
                {
                    section: "Hibajavítás:",
                    changes: ["Korlát számítás javítása postgres-en"]
                }
            ]
        },
        {
            version: "1.13.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Havi korlát kezelése a desktop felületen"]
                },
                {
                    section: "Technikai változás:",
                    changes: ["Vuetify frissítése 1.5.14-re"]
                }
            ]
        },
        {
            version: "1.12.3",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Kategória összegzés az összehasonlító statisztikában"]
                }
            ]
        },
        {
            version: "1.12.2",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Költség megjegyzésekben keresés az összehasonlító statisztikában"]
                }
            ]
        },
        {
            version: "1.12.1",
            changes: [
                {
                    section: "Hibajavítás:",
                    changes: ["Analítika nem működött (függőség verzió javítás)"]
                }
            ]
        },
        {
            version: "1.12.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Összehasonlító statisztika"]
                }
            ]
        },
        {
            version: "1.11.2",
            changes: [
                {
                    section: "Hibajavítás:",
                    changes: ["Android: egyenleg nullával való osztás hiba javítása"]
                }
            ]
        },
        {
            version: "1.11.1",
            changes: [
                {
                    section: "Hibajavítás:",
                    changes: ["Költség lista szépítése", "Dialógusok enter-re mentődnek, esc-re bezáródnak"]
                },
                {
                    section: "Technikai változás:",
                    changes: ["Kliens kód fésülés", "Vuetify frissítése 1.5.8-ra", "Vue frissítése 2.6.10-re"]
                }
            ]
        },
        {
            version: "1.11.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Átlag számítás a statisztikában"]
                }
            ]
        },
        {
            version: "1.10.1",
            changes: [
                {
                    section: "Hibajavítás:",
                    changes: ["Android: egyenleg versenyhelyzet javítása", "Android: egyenleg csak bejelentkezett felhasználónak engedélyezett"]
                }
            ]
        },
        {
            version: "1.10.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Android: aktuális egyenleg"]
                }
            ]
        },
        {
            version: "1.9.1",
            changes: [
                {
                    section: "Hibajavítás:",
                    changes: ["Android: frissítés javítása"]
                }
            ]
        },
        {
            version: "1.9.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Android: utoljára rögzített költségek listája"]
                },
                {
                    section: "Hibajavítás:",
                    changes: ["Android: duplikáció elkerülése automatikus szinkronizációnál"]
                }
            ]
        },
        {
            version: "1.8.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Android: automatikus szinkronizáció"]
                }
            ]
        },
        {
            version: "1.7.1",
            changes: [
                {
                    section: "Hibajavítás:",
                    changes: [
                        "Hét napjai hétfővel kezdődjenek",
                        "Költség keresés javítás",
                        "Vonalas analítikai statisztikánál y tengely látszódjon",
                        "Analítikai statisztika színei ne ismétlődjenek"
                    ]
                }
            ]
        },
        {
            version: "1.7.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Napi, havi analítikai statisztika", "Vonal analítikai statisztika", "Kategória választás a diagramban állítható"]
                },
                {
                    section: "Hibajavítás:",
                    changes: [
                        "Diagram cimke pozicionálás",
                        "Analítikai statisztika diagram cím változtatás",
                        "Nem használt összegzés törlése az analítikáról"
                    ]
                }
            ]
        },
        {
            version: "1.6.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Heti analítikai statisztika"]
                }
            ]
        },
        {
            version: "1.5.1",
            changes: [
                {
                    section: "Hibajavítás:",
                    changes: ["Megtakarítás számolás javítása üres listánál", "Firebase használata a hivatalos módon"]
                },
                {
                    section: "Technikai változás:",
                    changes: ["Verziók átalakítása adat vezéreltté", "Credits törlése a statisztika diagramjaiból"]
                }
            ]
        },
        {
            version: "1.5.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Megtakarítás kategória típus"]
                },
                {
                    section: "Hibajavítás:",
                    changes: ["Beállítások lekérdezése után nyiljon csak meg a költségek lista"]
                }
            ]
        },
        {
            version: "1.4.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Gomb csoport nyitási módja állítható (Beállítások)"]
                },
                {
                    section: "Technikai változás:",
                    changes: ["Vuetify frissítése 1.3.15-re", "Vue frissítése 2.5.21-re"]
                }
            ]
        },
        {
            version: "1.3.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Összegzés a leszűrt tételekre a költségek listánál"]
                }
            ]
        },
        {
            version: "1.2.1",
            changes: [
                {
                    section: "Hibajavítás:",
                    changes: ["Tábla kijelölés jobban látható legyen", "Költségeknél késleltett keresés a sok tétel miatt"]
                },
                {
                    section: "Technikai változás:",
                    changes: ["Vuetify frissítése 1.3.14-re"]
                }
            ]
        },
        {
            version: "1.2.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Időszaki szűrés a költségek listánál"]
                },
                {
                    section: "Technikai változás:",
                    changes: ["ORM bevezetése"]
                }
            ]
        },
        {
            version: "1.1.0",
            changes: [
                {
                    section: "Funkcionális változás:",
                    changes: ["Változás történet menü megjelenése", "Időszaki szűrés szépítése"]
                },
                {
                    section: "Technikai változás:",
                    changes: ["Vuetify frissítése 1.3.11-re", "Vue-cli 3-ra átállás"]
                }
            ]
        },
        {
            version: "1.0.0",
            changes: [
                {
                    section: "Publikus kiadás",
                }
            ]
        }
    ]
};
