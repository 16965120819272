<template>
    <simple-crud-list v-bind="options" @save="save">
        <template #form="{id, on}">
            <category-form ref="form" :id="id" v-on="on"/>
        </template>
        <template #item.name="{item}">
            <name-with-icon :value="item"/>
        </template>
        <template #item.monthlyLimit="{value}">
            <span v-if="value">{{ value | asCurrency }}</span>
        </template>
        <template #item.type="{value}">
            <v-icon :color="getIconDef(value).color" small>
                {{ getIconDef(value).icon }}
            </v-icon>
        </template>
    </simple-crud-list>
</template>

<script>
import {toCurrency} from "@/utils/CurrencyField/CurrencyUtils";
import CategoryForm from "@/category/components/CategoryForm";
import SimpleCrudList from "@/utils/crud/SimpleCrudList";
import NameWithIcon from "@/utils/icons/NameWithIcon";

const typeIconDef = {
    EXPENSE: {color: "red", icon: "mdi-arrow-top-left"},
    INCOME: {color: "green", icon: "mdi-call-received"},
    SAVE: {color: "blue", icon: "mdi-call-missed"}
};

export default {
    name: "category-list",
    components: {NameWithIcon, SimpleCrudList, CategoryForm},

    data: () => ({}),

    computed: {
        options() {
            return {
                headers: [
                    {text: "Név", value: "name"},
                    {text: "Havi korlát", value: "monthlyLimit", align: "right"},
                    {text: "Típus", value: "type", align: "center"}
                ],
                title: "Kategóriák",
                getter: this.$api.categories.getCategories,
                deleter: this.$api.categories.deleteCategory,
                deleteTitle: "Valóban törölni akarja a kategóriát?",
                deleteText: "Törlés előtt győzödjön meg róla, hogy a költség rögzítő alkalmazásban nincs szinkronizálandó költség. A kategória törlés után nem visszaállítható, hozzá tartozó költségek nem szinkronizálhatóak."
            };
        }
    },

    filters: {
        asCurrency: value => toCurrency(value),
    },

    methods: {
        getIconDef(value) {
            return typeIconDef[value];
        },

        save() {
            this.$refs.form.save();
        },
    }
};
</script>
