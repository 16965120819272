<template>
    <v-dialog ref="dialog" v-model="modal" persistent width="290">
        <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" v-if="isOnAndroid">
                <v-icon :color="color">mdi-calendar</v-icon>
            </v-btn>
            <v-text-field v-else :value="value | asHunDate" :label="label" prepend-icon="mdi-calendar" readonly dense
                          v-on="on" style="width: 170px;"/>
        </template>
        <v-row no-gutters class="primary pt-1 justify-center">
            <v-radio-group dark row v-model="range" hide-details class="pb-2">
                <v-radio label="Havi" :value="false"></v-radio>
                <v-radio label="Időszakos" :value="true"></v-radio>
            </v-radio-group>
        </v-row>
        <v-row no-gutters class="primary justify-center py-3 mt-n3">
            <span class="white--text">{{ date | asHunDate }}</span>
        </v-row>
        <v-date-picker v-model="date" scrollable type="month" locale="hu-hu" :range="range" no-title>
            <v-spacer></v-spacer>
            <cancel-button @click="modal = false">Mégsem</cancel-button>
            <ok-button @click="save" :disabled="!enoughMonthSelected">Kiválaszt</ok-button>
        </v-date-picker>
    </v-dialog>
</template>

<script>
import CancelButton from "@/utils/form/CancelButton";
import OkButton from "@/utils/form/OkButton";
import {toHunDate} from "@/utils/DatePicker/DateUtils";
import PlatformInfoMixin from "@/utils/platform/PlatformInfoMixin";

export default {
    name: "month-filter-picker",
    mixins: [PlatformInfoMixin],
    components: {OkButton, CancelButton},
    props: {
        value: [String, Array],
        label: String,
        color: String
    },

    data: () => ({
        modal: false,
        date: null,
        range: false
    }),

    computed: {
        enoughMonthSelected() {
            return this.range
                ? this.isArrayWith2Items(this.date)
                : this.date != null;
        },
    },

    methods: {
        save() {
            this.modal = false;
            this.$emit('input', this.date);
        },
        isArrayWith2Items(v) {
            return Array.isArray(v) && v.length === 2;
        }
    },

    filters: {
        asHunDate: value => toHunDate(value)
    },

    watch: {
        value: {
            handler(newValue) {
                this.date = newValue;
                this.range = this.isArrayWith2Items(newValue);
            },
            immediate: true
        },
        date(newValue) {
            if (this.isArrayWith2Items(newValue) && newValue[0] > newValue[1]) {
                this.date = [newValue[1], newValue[0]];
            }
        },
        range(isIt) {
            if (isIt && !Array.isArray(this.date)) {
                this.date = [this.date];
            } else if (!isIt && Array.isArray(this.date)) {
                this.date = this.date[0];
            }
        }
    }
};
</script>
