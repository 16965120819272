import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import router from './plugins/router';
import store from './plugins/store';
import vuetify from './plugins/vuetify';
import './plugins/firebase';
import './plugins/api';
import './plugins/curve';
import alertPlugin from "@/utils/alert/alertPlugin";
import '@/utils/dialog/dialogPlugin';
import './plugins/echarts';

Vue.use(alertPlugin);

Vue.use(require('vue-shortkey'));

Vue.config.productionTip = false;


new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');
