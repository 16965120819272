<template>
    <v-icon small :color="booleanIconDef[value].color">{{ booleanIconDef[value].icon }}</v-icon>
</template>

<script>

const booleanIconDef = {
    true: {color: "success", icon: "mdi-check"},
    false: {color: "error", icon: "mdi-close"}
};

export default {
    name: "boolean-icon",
    props: {value: Boolean},

    computed: {
        booleanIconDef() {
            return booleanIconDef;
        }
    }
};
</script>
