<template>
    <simple-crud-list v-bind="options" @save="save">
        <template #form="{id, on}">
            <main-category-form ref="form" :id="id" v-on="on"/>
        </template>
        <template #item.name="{item}">
            <name-with-icon :value="item"/>
        </template>
        <template #item.autoinclude="{value}">
            <boolean-icon :value="value"/>
        </template>
    </simple-crud-list>
</template>

<script>
import SimpleCrudList from "@/utils/crud/SimpleCrudList";
import MainCategoryForm from "@/category/components/MainCategoryForm";
import BooleanIcon from "@/utils/icons/BooleanIcon";
import NameWithIcon from "@/utils/icons/NameWithIcon";

export default {
    name: "main-category-list",
    components: {NameWithIcon, BooleanIcon, MainCategoryForm, SimpleCrudList},

    data: () => ({}),

    computed: {
        options() {
            return {
                headers: [
                    {text: "Név", value: "name"},
                    {text: "Statisztikában látszódik", value: "autoinclude", align: "center"}
                ],
                title: "Főkategóriák",
                getter: this.$api.mainCategories.getMainCategories,
                deleter: this.$api.mainCategories.deleteMainCategory,
                deleteTitle: "Valóban törölni akarja a főkategóriát?",
                deleteText: "A főkategória törlés után nem visszaállítható."
            };
        }
    },

    methods: {
        save() {
            this.$refs.form.save();
        },
    }
};
</script>
