<template>
    <v-icon :style="emptyIcon">mdi-circle-small</v-icon>
</template>
<script>
import {DEFAULT_COLOR} from "@/category/colors";

export default {
    name: 'empty-icon',
    props: {
        large: Boolean,
        color: {type: String, default: DEFAULT_COLOR}
    },

    computed: {
        emptyIcon() {
            return {
                width: this.size,
                height: this.size,
                'border-style': 'dashed',
                'border-radius': '50%',
                "border-width": 'thin',
                display: 'inline-block',
                'border-color': this.color,
                'color': 'transparent',
                'margin': '3px',
                'margin-top': this.margin
            };
        },

        size() {
            return this.large ? '28px' : '18px';
        },

        margin() {
            return this.large ? '8px' : '3px';
        }
    }
};
</script>
