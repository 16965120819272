<template>
    <v-card>
        <v-card-title class="text-h5">
            <v-row dense>
                <information title="Költségek">
                    <ul>
                        <li>A váratlan kiadások mindenhol az <v-icon small color="error">mdi-alert-outline</v-icon> ikonnal vannak megjelölve</li>
                    </ul>
                </information>
            </v-row>
            <v-row dense class="justify-end">
                <v-col cols="auto" class="pr-4 pt-3">
                    <debounced-search-field :value="search" @input="$emit('update:search', $event)"
                                            style="width: 200px"/>
                </v-col>
                <v-col cols="auto" class="pt-3">
                    <month-filter-picker :value="monthRange" @input="$emit('update:month-range', $event)"/>
                </v-col>
                <v-col cols="auto" class="pt-0">
                    <v-checkbox :value="onlyUnexpecteds" label="Váratlan" @change="$emit('update:only-unexpecteds', $event)" dense hide-details/>
                </v-col>
            </v-row>
        </v-card-title>
    </v-card>
</template>

<script>
import DebouncedSearchField from "@/utils/search/DebouncedSearchField";
import MonthFilterPicker from "@/utils/DatePicker/MonthFilterPicker";
import Information from "@/utils/information/Information";

export default {
    name: "cost-desktop-search",
    components: {Information, MonthFilterPicker, DebouncedSearchField},
    props: {
        search: String,
        monthRange: [String, Array],
        onlyUnexpecteds: Boolean
    },

    data: () => ({})
};
</script>
