<template>
    <v-card v-bind="options" ref="row" :class="{'even-row': index % 2 === 1, 'active-row':  !this.isOnAndroid, 'bottom-bordered-row': !last}">
        <v-card-text class="py-1" style="color: rgba(0, 0, 0, 0.87)">
            <slot></slot>
        </v-card-text>
    </v-card>
</template>

<script>
import PlatformInfoMixin from "@/utils/platform/PlatformInfoMixin";

export default {
    name: "row",
    mixins: [PlatformInfoMixin],
    props: {
        height: {type: [Number, String], required: true},
        index: {type: Number, required: true},
        last: Boolean
    },

    data: () => ({
    }),

    computed: {
        options() {
            return {
                elevation: this.isOnAndroid ? 1 : 0,
                height: this.height,
                dense: true,
                tile: !this.isOnAndroid
            };
        },
    },
};
</script>
<style scoped>
.even-row {
    background-color: rgba(0, 0, 0, .03);
}
.active-row:hover {
    background-color: #eeeeee;
}
.bottom-bordered-row {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
