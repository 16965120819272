<template>
    <v-btn color="primary" :disabled="disabled"
           @click="$emit('click')" v-shortkey.once="['enter']" @shortkey="$emit('click')">
        <slot/>
    </v-btn>
</template>

<script>
export default {
    name: "ok-button",
    props: {
        disabled: Boolean
    }
};
</script>
