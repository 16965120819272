<template>
    <cm-select v-bind="options" v-on="$listeners">
        <template #selection="{item}">
            <name-with-icon :value="item"/>
        </template>
        <template #item="{item}">
            <name-with-icon :value="item"/>
        </template>
    </cm-select>
</template>

<script>
import NameWithIcon from "@/utils/icons/NameWithIcon";
import CmSelect from "@/utils/form/CmSelect";

export default {
    name: "name-with-icon-select",
    components: {NameWithIcon, CmSelect},
    props: {
        value: Number,
        label: String,
        items: Array,
        rules: Array,
        disabled: Boolean,
        autoSelectFirst: Boolean,
        dense: Boolean,
        clearable: Boolean
    },

    data: () => ({}),

    computed: {
        options() {
            return ({
                value: this.value,
                label: this.label,
                items: this.items,
                rules: this.rules,
                disabled: this.disabled,
                autoSelectFirst: this.autoSelectFirst,
                dense: this.dense,
                itemText: "name",
                itemValue: "id",
                clearable: this.clearable
            });
        }
    },
};
</script>
