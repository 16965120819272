nagymezei.hunagymezei.hunagymezei.hu<template>
    <div>
        <app-bar-menu>
            <template #default="{color}">
                <span :class="[`${color}--text`, 'headline-2']">Curve integráció</span>
            </template>
        </app-bar-menu>

        <v-card flat>
            <v-card-text>
                <v-row dense v-for="(info, idx) in allItems" :key="idx">
                    <v-col cols="12" class="d-flex justify-space-between text-subtitle-1">
                        <div>{{ info.label }}</div>
                        <div>{{ info.value }}</div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-footer absolute tile padless>
            <v-card flat width="100%">
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-btn color="primary" width="100%" @click="importRows"
                                       :disabled="!loggedInAndHasImportableRows">
                                    Importálás
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-btn color="primary" width="100%" outlined @click="importRows(true)"
                                       :disabled="!loggedInAndHasImportableRows">
                                    Mindent importál
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-footer>
    </div>
</template>
<script>
import AppBarMenu from "@/utils/menu/AppBarMenu";
import moment from "moment";
import AuthenticationMixin from "@/auth/AuthenticationMixin";

export default {
    name: "curve",
    mixins: [AuthenticationMixin],
    components: {AppBarMenu},
    props: {
        text: String
    },
    data() {
        return {
            lastImport: null,
            importableRows: 0,
            headers: [
                {text: "", value: "label"},
                {text: "", value: "value"},
            ],
            infos: []
        };
    },

    computed: {
        allItems() {
            let items = [
                {label: "Feltöltendő rekordok száma:", value: this.importableRows},
                {label: "Utoljára feltölve:", value: this.lastImport},
            ];
            this.infos.forEach(it => items.push(it));
            return items;
        },

        loggedInAndHasImportableRows() {
            return this.importableRows > 0 && this.isLoggedIn;
        }
    },

    methods: {
        importRows(all = false) {
            this.$api.integration.importRows(this.text, all).then(info => {
                this.infos.push({label: 'Importált rekordok száma:', value: info.successfullyImported});
                this.infos.push({label: 'Már importált rekordok száma:', value: info.skippedAtImport});
                if (info.errorIndexes.length > 0) {
                    this.infos.push({label: 'Hibák száma az import során:', value: info.errorIndexes.length});
                }
            });
        },

        loadWhenSignedIn() {
            this.$api.integration.lastImport()
                .then(info => this.lastImport = moment(info, moment.DATETIME_LOCAL_MS).format('YYYY.MM.DD HH:mm:ss'));
        }
    },

    mounted() {
        if (this.text != null) {
            this.importableRows = Math.max(this.text.split("\n").length - 1, 0);
        }
    }
};
</script>
