import firebase from 'firebase/app';
import 'firebase/auth';
import {registerWebPlugin, WebPlugin} from "@capacitor/core";

const config = {
    apiKey: "AIzaSyDiotx1VErl5BY1-8a4uH4NkhnPAEkObSk",
    authDomain: "costmanagement-192015.firebaseapp.com",
    databaseURL: "https://costmanagement-192015.firebaseio.com",
    projectId: "costmanagement-192015",
    storageBucket: "costmanagement-192015.appspot.com",
    messagingSenderId: "146638642255"
};
firebase.initializeApp(config);

export class FirebaseAuthWeb extends WebPlugin {
    constructor() {
        super({
            name: 'FirebaseAuth',
            platforms: ['web']
        });
        firebase.auth().onAuthStateChanged(user => this.notifyListeners('authChanged', user));
    }
}

const FirebaseAuth = new FirebaseAuthWeb();
export {FirebaseAuth};

registerWebPlugin(FirebaseAuth);
