<template>
    <v-badge color="transient">
        <template #badge>
            <v-icon color="primary" @click="show = true">
                mdi-information-outline
            </v-icon>
            <v-dialog v-model="show" v-if="show" :width="width">
                <v-card v-shortkey.once="['esc']" @shortkey="show = false">
                    <v-card-title>
                        {{ title }}
                    </v-card-title>
                    <v-card-text>
                        <slot/>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </template>
        {{ title }}
    </v-badge>
</template>

<script>
export default {
    name: "information",
    props: {
        title: String,
        width: {type: [Number, String], default: 600}
    },

    data: () => ({
        show: false
    }),
};
</script>
