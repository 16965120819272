<template>
    <name-with-icon-select :items="categories" :label="mapping.name" v-model="mapping.category" :disabled="disabled"
                     :rules="rules.category" dense class="pt-3"/>
</template>

<script>
import VuetifyJetValidator from "vuetify-jet-validator";
import NameWithIconSelect from "@/utils/icons/NameWithIconSelect";

const validator = new VuetifyJetValidator();

export default {
    name: "curve-category-based-mapping",
    components: {NameWithIconSelect},
    props: {
        value: {type: Object, required: true},
        disabled: Boolean,
        categories: Array
    },

    data: () => ({
        rules: {
            category: [validator.rules.required("Kategória kötelező")],
        }
    }),

    computed: {
        mapping: {
            get() {
                return this.value;
            },
            set(mapping) {
                this.$emit('input', mapping);
            }
        }
    }
};
</script>
