import MobileCreateCost from "./MobileCreateCost";
import MobileApp from "./MobileApp";
import Curve from "../curve/Curve";
import CostList from "@/cost/CostList";
import Dashboard from "@/statistics/Dashboard";

export default [
    {
        path: '/mobile',
        component: MobileApp,
        children : [
            {
                path: 'cost',
                component: MobileCreateCost
            },
            {
                path: 'costs',
                component: CostList
            },
            {
                path: 'dashboard',
                component: Dashboard
            },
            {
                name: 'Curve',
                path: 'curve',
                component: Curve,
                props: true
            },
        ]
    },
];
