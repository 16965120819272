<template>
    <v-card :height="chartParentHeight" v-resize="resizeChart">
        <v-card-title class="text-h5">
            <categories-diagram-information style="padding-top: 6px"/>
        </v-card-title>
        <v-card-text ref="chartParent">
            <v-chart :options="diagram" autoresize ref="chart" @contextmenu="selectMainCategory"/>
            <v-menu v-model="menu.visible" :position-x="menu.x" :position-y="menu.y" absolute ref="menu" v-if="menu.visible">
                <v-list dense>
                    <v-list-item v-for="(mainCat, idx) in mainCategories" :key="idx">
                        <v-list-item-icon v-shortkey.once="['esc']" @shortkey="menu.visible = false">
                            <v-simple-checkbox v-model="visibleMainCategories[idx]" v-ripple
                                               :color="mainCategoryColor(idx)" :disabled="!canSelectMainCategory(idx)"/>
                        </v-list-item-icon>
                        <v-list-item-content :style="`color: ${mainCategoryColor(idx)}`">
                            {{ mainCat.name }}
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-card-text>
    </v-card>
</template>

<script>

import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';

import _ from "lodash";

import {DASHBOARD_CONFIG} from "@/statistics/components/CategoriesDiagramConfig";
import {CATEGORY_COLORS, MAIN_CATEGORY_COLORS} from "@/category/colors";
import {toPath} from "@/utils/DatePicker/DateUtils";
import {toCurrency} from "@/utils/CurrencyField/CurrencyUtils";
import CategoriesDiagramInformation from "@/statistics/components/CategoriesDiagramInformation";

export default {
    name: "categories-diagram",
    components: {CategoriesDiagramInformation},
    data() {
        return {
            mainCategories: [],
            visibleMainCategories: [],
            chartParentHeight: 100,
            menu: {
                visible: false,
                x: 0,
                y: 0
            }
        };
    },

    computed: {
        diagram() {
            let customization = {
                tooltip: {formatter: this.formatTooltip},
                series: [
                    {data: this.mainCategoriesData},
                    {data: this.categoriesData, label: {formatter: this.formatLabel}}
                ]
            };
            return _.merge({}, DASHBOARD_CONFIG, customization);
        },

        categoriesData() {
            return this.mainCategories
                .filter((it, idx) => this.visibleMainCategories[idx])
                .flatMap(it => it.categories)
                .map((it, idx) => ({name: it.name, value: it.cost, itemStyle: {color: it.color ?? CATEGORY_COLORS[idx]}}));
        },

        mainCategoriesData() {
            return this.mainCategories
                .filter((it, idx) => this.visibleMainCategories[idx])
                .map((it, idx) => ({name: it.name, value: it.cost, itemStyle: {color: it.color ?? MAIN_CATEGORY_COLORS[idx]}}));
        },
    },

    methods: {
        formatTooltip(params) {
            if (!params.data) {
                return;
            }
            return `<i>${params.seriesName}</i> <br/>${params.name}: <b>${toCurrency(params.data.value)}</b> (${params.percent}%)`;
        },

        formatLabel(params) {
            if (!params.data) {
                return;
            }
            return `{a|${params.name}}: {b|${toCurrency(params.data.value)}}  {per|${params.percent}%}`;
        },

        resizeChart() {
            let parentDimension = this.$refs.chartParent.getBoundingClientRect();
            this.chartParentHeight = window.innerHeight - parentDimension.top - 16;
            if (this.chartParentHeight < parentDimension.width) {
                this.chartParentHeight = parentDimension.width;
            }
            this.$refs.chart.$el.style.width = `${parentDimension.width - 32}px`;
            this.$refs.chart.$el.style.height = `${this.chartParentHeight - 32}px`;
        },

        reloadDiagram(monthRange, average) {
            this.$api.mainCategories.getExpenseStatistics(toPath(monthRange), average)
                .then(stat => this.mainCategories = stat)
                .then(() => this.visibleMainCategories = this.mainCategories.map(() => true));
        },

        selectMainCategory(e) {
            e.event.event.preventDefault();

            if (e.seriesName !== "Főkategória") {
                return;
            }

            this.menu.x = e.event.event.clientX - 150;
            this.menu.y = e.event.event.clientY;
            this.$nextTick(function () {
                this.menu.visible = true;
            });
        },

        canSelectMainCategory(idx) {
            return !this.visibleMainCategories[idx] || this.visibleMainCategories.filter(it => it).length > 1;
        },

        mainCategoryColor(idx) {
            return MAIN_CATEGORY_COLORS[idx];
        }
    },

    mounted() {
        this.$nextTick(function () {
            this.resizeChart();
        });
    }
};
</script>
