<template>
    <v-form ref="form">
        <v-text-field label="Email*" v-model="user.email" required :rules="rules.email"/>
        <v-autocomplete v-if="isAdmin" label="Csoport*"
                        :items="groups" v-model="user.groupId" item-text="name" item-value="id"
                        :rules="rules.groupId"/>
        <v-switch v-if="isAdmin" label="Adminisztrátor" v-model="user.admin" color="primary"
                  hide-details></v-switch>
    </v-form>
</template>

<script>
import VuetifyJetValidator from "vuetify-jet-validator";

const validator = new VuetifyJetValidator();

export default {
    name: "user-form",
    props: {
        id: [String, Number]
    },

    data: () => ({
        user: {
            id: null,
            email: null,
            groupId: null
        },
        rules: {
            email: [
                validator.rules.required('Email kötelező'),
                validator.rules.email('Hibás email cím'),
            ],
            groupId: [validator.rules.required('Csoport kötelező')]
        },
        groups: [],
        currentUser: null
    }),

    computed: {
        isAdmin() {
            return this.currentUser?.admin;
        }
    },

    methods: {
        save() {
            if (!validator.formIsValid(this.$refs.form)) {
                return;
            }
            this.$api.users.saveUser(this.user)
                .then(() => this.$success('Sikeres mentés'))
                .then(() => this.$emit('saved'));
        },
    },

    mounted() {
        this.$api.groups.getGroups().then(groups => this.groups = groups);
        this.$api.users.getCurrentUser()
            .then(user => this.currentUser = user)
            .then(() => {
                if (!this.id) {
                    this.user.groupId = this.currentUser.groupId;
                }
            });

        if (this.id) {
            this.$api.users.getUser(this.id).then(user => this.user = user);
        }
    }
};
</script>
