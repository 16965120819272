export default ctx => ({
    costs: {
        getCost(id) {
            return ctx.$axios.get("/cost/" + id).then(response => response.data);
        },
        getCosts(monthRange, search, onlyUnexpecteds = false) {
            return ctx.$axios.get(`/cost/${monthRange.from}/${monthRange.to}?search=${search ?? ''}&onlyUnexpecteds=${onlyUnexpecteds}`)
                .then(response => response.data);
        },
        getSummary(monthPath, search = "", average = false, onlyUnexpecteds = false) {
            return ctx.$axios.get(`/cost/summary/${monthPath}?average=${average}&search=${search ?? ""}&onlyUnexpecteds=${onlyUnexpecteds}`)
                .then(response => response.data);
        },
        deleteCosts(ids) {
            return ctx.$axios.delete("/cost?" + ids.map(id => `ids=${id}`).join("&"));
        },
        saveCost(cost) {
            return ctx.$axios.put("/cost", cost);
        },
        refactorCost(fromId, cost) {
            return ctx.$axios.put(`/cost/${fromId}/move`, cost);
        },
        getMultiCost(ids) {
            return ctx.$axios.get("/multicost?" + ids.map(id => `ids=${id}`).join("&"))
                .then(response => response.data);
        },
        saveMultiCost(cost) {
            return ctx.$axios.put("/multicost", cost);
        },

    }
});
