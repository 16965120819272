<template>
    <v-navigation-drawer app :permanent="isDesktop" v-model="visible">
        <v-list nav>
            <v-list-item :href="`${baseUri}/android/app-release.apk`" v-if="isWebOnAndroid">
                <v-list-item-icon>
                    <v-icon>mdi-download</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Alkalmazás telepítése</v-list-item-content>
            </v-list-item>
            <v-list-item @click="saveApp" v-if="isAndroidApp" :disabled="!newerVersion">
                <v-list-item-icon>
                    <v-icon :disabled="!newerVersion">mdi-download</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <span>Frissítés
                        <v-chip v-if="newerVersion" color="gray" class="align-self-end">
                            {{ newerVersion }}
                        </v-chip>
                    </span>
                </v-list-item-content>
            </v-list-item>

            <v-list-item to="/dashboard" v-if="!isOnAndroid">
                <v-list-item-icon>
                    <v-icon>mdi-view-dashboard</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Áttekintés</v-list-item-content>
            </v-list-item>
            <v-list-item to="/costs" v-if="!isOnAndroid">
                <v-list-item-icon>
                    <v-icon>mdi-currency-eur</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Költségek</v-list-item-content>
            </v-list-item>

            <v-divider/>
            <v-list-item>
                <v-list-item-subtitle>Adminisztráció</v-list-item-subtitle>
            </v-list-item>
            <v-list-item to="/categories" v-if="!isOnAndroid">
                <v-list-item-icon>
                    <v-icon>mdi-format-list-bulleted</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Kategóriák</v-list-item-content>
            </v-list-item>
            <v-list-item to="/pockets" v-if="!isOnAndroid">
                <v-list-item-icon>
                    <v-icon>mdi-folder-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Zsebek</v-list-item-content>
            </v-list-item>
            <v-list-item to="/users" v-if="!isOnAndroid">
                <v-list-item-icon>
                    <v-icon>mdi-account-group-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Felhasználók</v-list-item-content>
            </v-list-item>
            <v-list-item to="/curveSettings" v-if="!isOnAndroid">
                <v-list-item-icon>
                    <v-icon>$curve</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Curve integráció</v-list-item-content>
            </v-list-item>

            <v-divider/>
            <v-list-item to="/history" v-if="!isOnAndroid">
                <v-list-item-icon>
                    <v-icon>mdi-history</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Történet</v-list-item-content>
            </v-list-item>
        </v-list>

        <template #append>
            <v-list-item @click="signIn" v-if="isAndroidApp" :disabled="isLoggedIn">
                <v-list-item-icon>
                    <v-icon>mdi-login</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Bejelentkezés</v-list-item-content>
            </v-list-item>
            <v-list-item @click="signOut" :disabled="!isLoggedIn">
                <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Kijelentkezés</v-list-item-content>
            </v-list-item>
        </template>
    </v-navigation-drawer>
</template>
<script>

import '@capacitor-community/http';
import {Plugins} from '@capacitor/core';
import PlatformInfo from "../platform/PlatformInfoMixin";
import Authentication from "../../auth/AuthenticationMixin";

const {OTAPlugin} = Plugins;

export default {
    name: 'app-menu',
    mixins: [PlatformInfo, Authentication],

    data: () => ({
        newerVersion: null,
    }),

    computed: {
        visible: {
            get: function () {
                return this.$store.state.Menu.visible;
            },
            set: function (newValue) {
                if (newValue != this.$store.state.Menu.visible) {
                    this.$store.dispatch('Menu/toggleMenuVisibility');
                }
            }
        },
    },

    methods: {
        async checkVersion() {
            let actualVersion = null;
            OTAPlugin.getApplicationVersion()
                .then(info => actualVersion = info.versionCode)
                .then(() => OTAPlugin.getLatestApplicationVersion({url: `${this.baseUri}/android/output-metadata.json`}))
                .then(response => {
                    if (response.elements[0].versionCode > actualVersion) {
                        this.newerVersion = response.elements[0].versionName;
                    }
                });
        },
        saveApp() {
            OTAPlugin.otaInstall({url: `${this.baseUri}/android/app-release.apk`});
        },
        menuClosed() {
            console.log(this.visible);
            if (!this.visible) {
                console.log("menuClosed");
                this.toggleMenuVisibility();
            }
        }
    },

    mounted() {
        this.checkVersion();
    }
};
</script>
