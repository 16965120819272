<template>
    <v-card>
        <v-card-title class="pa-0 pb-4">
            <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Ikon választás</v-toolbar-title>
                <v-spacer></v-spacer>
                <color-picker v-model="selectedColor"/>
                <v-spacer></v-spacer>
                <search-field v-model="search" style="width: 150px;"></search-field>
            </v-toolbar>
        </v-card-title>
        <v-card-text class="pr-0">
            <v-virtual-scroll :items="visibleIcons" :item-height="50" height="300" bench="1">
                <template v-slot="{ item: iconRow }">
                    <v-row no-gutters>
                        <v-col v-for="icon in iconRow" :key="icon" cols="2">
                            <icon-picker-item :color="selectedColor" :value="icon" :selected-icon="selectedIcon"
                                              @input="selectedIcon = $event"/>
                        </v-col>
                    </v-row>
                </template>
            </v-virtual-scroll>
        </v-card-text>
        <v-card-actions>
            <v-btn color="primary" text @click="selectedIcon = null">Törlés</v-btn>
            <v-spacer></v-spacer>
            <cancel-button @click="$emit('cancel')">Mégsem</cancel-button>
            <ok-button @click="save">Kiválaszt</ok-button>
        </v-card-actions>
    </v-card>
</template>

<script>
import materialDesignIcons from "./materialDesignIcons";
import _ from "lodash";
import IconPickerItem from "@/utils/IconPicker/IconPickerItem";
import SearchField from "@/utils/search/SearchField";
import CancelButton from "@/utils/form/CancelButton";
import OkButton from "@/utils/form/OkButton";
import ColorPicker from "@/utils/ColorPicker/ColorPicker";

export default {
    name: "icon-picker-dialog",
    components: {ColorPicker, OkButton, CancelButton, SearchField, IconPickerItem},
    props: {
        value: String,
        color: String
    },

    data() {
        return {
            selectedIcon: null,
            selectedColor: null,
            icons: materialDesignIcons.icons.map(it => it.name),
            search: '',
        };
    },

    computed: {
        visibleIcons() {
            let filterText = (this.search ?? '').toLocaleLowerCase();
            return _.chunk(this.icons.filter(it => it.indexOf(filterText) != -1), 6);
        }
    },

    methods: {
        save() {
            this.$emit('input', this.selectedIcon);
            this.$emit('update:color', this.selectedColor);
        }
    },

    mounted() {
        this.selectedIcon = this.value;
        this.selectedColor = this.color;
    }
};
</script>
