var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g({ref:"table",attrs:{"hide-default-footer":"","disable-pagination":"","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"show-select":_vm.showSelect,"search":_vm.search,"value":_vm.value,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),(_vm.items.length > 0)?{key:"body",fn:function(ref){
var items = ref.items;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('tbody',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.resizeTableMaxHeight),expression:"resizeTableMaxHeight"}],ref:"tableBody"},[_c('td',{staticClass:"pt-1",attrs:{"colspan":_vm.headers.length + 1}},[_c('v-virtual-scroll',{key:_vm.refreshForcer,attrs:{"items":items,"item-height":_vm.correctedItemHeight,"bench":_vm.isOnAndroid ? 1 : 5,"max-height":_vm.maxHeight},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('row',{ref:"itemRow",attrs:{"index":index,"height":_vm.itemHeight,"last":index === items.length - 1}},[_vm._t("item",null,null,{ item: item, index: index, isSelected: isSelected, select: select, itemHeight: _vm.itemHeight })],2)]}}],null,true)})],1)])]}}:null],null,true)},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }