<template>
    <div>
        <v-icon v-if="value.icon" :color="value.color" :large="large">{{ value.icon }}</v-icon>
        <empty-icon :color="value.color" :large="large" v-else/>
        <span class="pl-2" v-if="!hideName">{{ value.name }}</span>
    </div>
</template>

<script>
import EmptyIcon from "./EmptyIcon";
import {DEFAULT_COLOR} from "@/category/colors";

export default {
    name: "name-with-icon",
    components: {EmptyIcon},
    props: {
        value: Object,
        hideName: {type: Boolean, default: false},
        large: {type: Boolean, default: false}
    },
    data: () => ({}),

    computed: {
        color() {
            return this.value.color ?? DEFAULT_COLOR;
        }
    }
};
</script>

