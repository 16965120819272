export const DASHBOARD_CONFIG = {
    textStyle: {
        fontFamily: 'Roboto',
    },
    tooltip: {
        trigger: 'item',
    },
    series: [
        {
            name: 'Főkategória',
            type: 'pie',
            selectedMode: 'single',
            radius: [0, '30%'],
            label: {
                position: 'inner'
            },
            labelLine: {
                show: false
            },
        },
        {
            name: 'Kategória',
            type: 'pie',
            radius: ['40%', '55%'],
            label: {
                backgroundColor: '#eee',
                borderColor: '#aaa',
                borderWidth: 1,
                borderRadius: 4,
                rich: {
                    a: {
                        color: '#999',
                        lineHeight: 20,
                        align: 'center',
                        padding: [0, 2, 0, 2]
                    },
                    hr: {
                        borderColor: '#aaa',
                        width: '100%',
                        borderWidth: 0.5,
                        height: 0
                    },
                    b: {
                        fontSize: 12,
                        lineHeight: 20
                    },
                    per: {
                        color: '#eee',
                        backgroundColor: '#334455',
                        padding: [2, 2],
                        borderRadius: 2
                    }
                }
            },
        }
    ]
};
