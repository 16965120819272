export default ctx => ({
    mainCategories: {
        getMainCategories() {
            return ctx.$axios.get("/mainCategory")
                .then(response => response.data);
        },
        getMainCategory(id) {
            return ctx.$axios.get("/mainCategory/" + id)
                .then(response => response.data);
        },
        saveMainCategory(category) {
            return ctx.$axios.put("/mainCategory", category);
        },
        deleteMainCategory(id) {
            return ctx.$axios.delete("/mainCategory/" + id);
        },
        getExpenseStatistics(monthPath, average = false) {
            return ctx.$axios.get(`/mainCategory/statistic/expense/${monthPath}?average=${average}`)
                .then(response => response.data);
        }
    }
});
