<template>
    <v-text-field v-model="localValue" v-bind="options"/>
</template>

<script>

export default {
    name: "search-field",
    props: {
        value: String
    },

    data: () => ({
        localValue: null
    }),

    computed: {
        options() {
            return ({
                clearable: true,
                dense: true,
                hideDetails: true,
                placeholder: "Keresés",
                prependIcon: "mdi-magnify",
            });
        }
    },
    watch: {
        value: {
            handler(newValue) {
                this.localValue = newValue;
            },
            immediate: true
        },
        localValue() {
            this.localValueChanged();
        }
    },

    methods: {
        localValueChanged() {
            this.$emit('input', this.localValue);
        }
    }
};
</script>
