<template>
    <v-form ref="form">
        <v-container fluid>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-text-field label="Név" v-model="group.name" required :rules="rules.name"></v-text-field>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import VuetifyJetValidator from "vuetify-jet-validator";

const validator = new VuetifyJetValidator();

export default {
    name: "group-form",
    props: {
        id: [String, Number]
    },

    data: () => ({
        group: {
            id: null,
            name: null
        },
        rules: {
            name: [validator.rules.required('Név kötelező')]
        }
    }),

    methods: {
        save() {
            if (!validator.formIsValid(this.$refs.form)) {
                return;
            }
            this.$api.groups.saveGroup(this.group)
                .then(() => this.$success('Sikeres mentés'))
                .then(() => this.$emit('saved'));
        },
    },

    mounted() {
        if (this.id) {
            this.$api.groups.getGroup(this.id).then(group => this.group = group);
        }
    }
};
</script>
