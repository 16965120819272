<template>
    <div class="title">
        <v-dialog :value="true" persistent max-width="400">
            <v-card>
                <v-responsive>
                    <v-card-title>Bejelentkezés</v-card-title>
                    <v-card-actions>
                        <v-btn text color="primary" v-if="isWebOnAndroid"
                               :href="`${baseUri}/android/app-release.apk`">
                            <v-icon class="pr-2">mdi-download</v-icon>
                            Letöltés
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="signIn" color="primary" :loading="!showLogin">
                            <v-icon class="pr-2">mdi-google-downasaur</v-icon>
                            Belépés
                        </v-btn>
                    </v-card-actions>
                </v-responsive>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import PlatformInfoMixin from "../utils/platform/PlatformInfoMixin";
import AuthenticationMixin from "./AuthenticationMixin";

export default {
    name: 'Home',
    mixins: [PlatformInfoMixin, AuthenticationMixin],

    data: () => ({
        showLogin: false
    }),

    methods: {
        async enableLoginButton() {
            setTimeout(() => this.showLogin = true, 2000);
        }
    },

    watch: {
        isLoggedIn() {
            if (this.isLoggedIn) {
                this.$router.replace('/dashboard');
            }
        }
    },

    mounted() {
        this.enableLoginButton();
    }
};
</script>
