<template>
    <information title="Költség eloszlás">
        <ul>
            <li>A belső körön a főkategóriák helyezkednek el</li>
            <li>A külső körön a kategóriák a főkategóriák által határolt cikkelyen vannak</li>
            <li>A főkategóriákon jobb egér gombot megnyomva kiválasztható mely főkategóriák
                (és a hozzátartozó kategóriák) láthatóak</li>
        </ul>
    </information>
</template>

<script>
import Information from "@/utils/information/Information";

export default {
    name: "categories-diagram-information",
    components: {Information},
};
</script>
