import PlatformInfoMixin from "@/utils/platform/PlatformInfoMixin";

export default ({
    mixins: [PlatformInfoMixin],

    computed: {
        costItemHeight() {
            return this.isMobile ? 74 : 54;
        },

        bottomPadding() {
            return 72;
        }
    }
});
