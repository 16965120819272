<template>
    <v-btn color="primary" text @click="$emit('click')" v-shortkey.once="['esc']" @shortkey="$emit('click')">
        <slot/>
    </v-btn>
</template>

<script>
export default {
    name: "cancel-button"
};
</script>
