<template>
    <simple-crud-list v-bind="options" @save="save">
        <template #form="{id, on}">
            <group-form ref="form" :id="id" v-on="on"/>
        </template>
    </simple-crud-list>

</template>

<script>
import GroupForm from "@/auth/components/GroupForm";
import SimpleCrudList from "@/utils/crud/SimpleCrudList";
export default {
    name: "group-list",
    components: {SimpleCrudList, GroupForm},

    data: () => ({}),

    computed: {
        options() {
            return {
                headers: [
                    {text: "Név", value: "name"}
                ],
                title: "Csoportok",
                getter: this.$api.groups.getGroups,
            };
        }
    },

    methods: {
        save() {
            this.$refs.form.save();
        },
    }
};
</script>
