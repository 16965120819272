<template>
    <v-app-bar app :dark="!isOnAndroid" :color="isOnAndroid ? 'white' : 'primary'" elevation="0" dense>
        <v-app-bar-nav-icon v-if="isMobile" @click="toggleMenuVisibility"
                            :style="isOnAndroid ? 'color: var(--v-primary-base);' : ''"/>
        <slot name="default" v-bind="{color: isOnAndroid ? 'primary' : 'white'}"/>
    </v-app-bar>
</template>
<script>
import PlatformInfo from "../platform/PlatformInfoMixin";
import {mapActions} from 'vuex';

export default {
    name: 'app-bar-menu',
    mixins: [PlatformInfo],

    methods: {
        ...mapActions('Menu', ['toggleMenuVisibility']),
    }
};
</script>
