<template>
    <v-form ref="form">
        <v-container fluid>
            <v-row no-gutters>
                <v-col cols="12">
                    <date-picker v-model="cost.date" label="Dátum"/>
                </v-col>
                <v-col cols="12" v-if="pockets.length > 0">
                    <name-with-icon-select v-model="cost.sourcePocket" label="Zseb" :items="pockets" clearable/>
                </v-col>
                <v-col cols="12">
                    <name-with-icon-select v-model="cost.category" label="Kategória" :items="categories" clearable/>
                </v-col>
                <v-col cols="12">
                    <v-radio-group v-model="cost.descriptionOperation" row>
                        <v-radio label="Hozzáfűzés" value="append"/>
                        <v-radio label="Módosítás" value="modify"/>
                    </v-radio-group>
                </v-col>
                <v-col cols="12">
                    <v-text-field v-model="cost.description" label="Megjegyzés" hide-details/>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>
<script>
import DatePicker from "@/utils/DatePicker/DatePicker";
import NameWithIconSelect from "@/utils/icons/NameWithIconSelect";
import VuetifyJetValidator from "vuetify-jet-validator";

const validator = new VuetifyJetValidator();

export default {
    name: 'cost-multi-edit-panel',
    components: {NameWithIconSelect, DatePicker},
    props: {
        ids: Array
    },

    data: () => ({
        cost: {
            ids: [],
            date: null,
            category: null,
            sourcePocket: null,
            descriptionOperation: "append",
            description: null
        },

        categories: [],
        pockets: []
    }),

    methods: {
        save() {
            if (!validator.formIsValid(this.$refs.form)) {
                return;
            }

            this.$api.costs.saveMultiCost(this.cost)
                .then(() => this.$success('Sikeres mentés'))
                .then(() => this.$emit('saved'));
        },
    },

    mounted() {
        this.$api.costs.getMultiCost(this.ids).then(costs => this.cost = costs);
        this.$api.categories.getCategories(true).then(categories => this.categories = categories);
        this.$api.pockets.getPocketsOnly().then(pockets => this.pockets = pockets);
    }
};
</script>
