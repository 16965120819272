<template>
    <v-form ref="form">
        <v-container fluid>
            <v-row>
                <v-col cols="6">
                    <v-card>
                        <v-card-title class="headline">
                            <pocket-information/>
                            <v-btn color="primary" fab dark small right absolute class="mt-2 mr-2"
                                   @click="createPocket">
                                <v-icon>$create</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <div v-if="!pocket.pockets.length" class="text-center">Nincs zseb megadva</div>
                            <scrollable-div bottom-padding="88" class="py-4" ref="pocketContainer">
                                <draggable class="pocket" :list="pocket.pockets" handle=".handle">
                                    <pocket v-for="(pck, idx) in pocket.pockets" :key="idx" :first="idx === 0"
                                            :non-unique-names="nonUniquePockets" v-model="pocket.pockets[idx]"
                                            :types="validTypes" :all-pockets="pocket.pockets" ref="pocket"
                                            @delete="deletePocket(idx)"/>
                                </draggable>
                            </scrollable-div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="3">
                    <v-card>
                        <v-card-title class="headline pt-3">
                            Típus
                            <v-btn color="primary" fab dark small right absolute class="mt-2 mr-2" @click="createType">
                                <v-icon>$create</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <div v-if="pocket.pocketTypes.length === 0" class="text-center">Nincs típus megadva</div>
                            <scrollable-div bottom-padding="88" class="py-4" ref="typeContainer">
                                <pocket-type v-for="(type, idx) in pocket.pocketTypes" :key="idx" :first="idx === 0"
                                             :non-unique-names="nonUniqueTypes" @delete="deleteType(idx)"
                                             v-model="pocket.pocketTypes[idx]" ref="type"/>
                            </scrollable-div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-footer absolute color="white" class="px-3 pb-4">
                <v-row>
                    <v-col>
                        <v-card>
                            <v-card-actions>
                                <v-spacer/>
                                <cancel-button @click="cancel">Mégsem</cancel-button>
                                <ok-button @click="save">Mentés</ok-button>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-footer>
        </v-container>
    </v-form>
</template>

<script>
import PocketInformation from "@/pocket/components/PocketInformation";
import CancelButton from "@/utils/form/CancelButton";
import OkButton from "@/utils/form/OkButton";
import ScrollableDiv from "@/utils/ScrollableDiv/ScrollableDiv";
import PocketType from "@/pocket/components/PocketType";
import Pocket from "@/pocket/components/Pocket";
import draggable from "vuedraggable";
import {DirtyCheck} from "@/utils/dirty/DirtyCheck";

export default {
    name: "pockets",
    mixins: [DirtyCheck],
    components: {Pocket, PocketType, ScrollableDiv, OkButton, CancelButton, PocketInformation, draggable},

    data: () => ({
        pocket: {
            version: 0,
            pocketTypes: [],
            pockets: [],
        },
        idSeq: 0,
    }),

    computed: {
        nonUniqueTypes() {
            return this.nonUniqueNames(this.pocket.pocketTypes.map(it => it.name));
        },
        validTypes() {
            return this.pocket.pocketTypes.filter(it => it.name);
        },
        nonUniquePockets() {
            return this.nonUniqueNames(this.pocket.pockets.map(it => it.name));
        },
    },

    methods: {
        createPocket() {
            this.pocket.pockets.push({
                name: null,
                id: --this.idSeq,
                icon: null,
                color: null,
                saved: false,
                type: null,
                costAssigned: false,
                cardNumbers: []
            });
            this.$nextTick(function () {
                this.$refs.pocket[this.pocket.pockets.length - 1].focus();
                this.$refs.pocketContainer.goToEnd();
            });
        },

        deletePocket(idx) {
            this.pocket.pockets.splice(idx, 1);
        },

        createType() {
            this.pocket.pocketTypes.push({name: null, id: --this.idSeq});
            this.$nextTick(function () {
                this.$refs.type[this.pocket.pocketTypes.length - 1].focus();
                this.$refs.typeContainer.goToEnd();
            });
        },

        deleteType(idx) {
            let typeId = this.pocket.pocketTypes[idx].id;
            this.pocket.pockets.filter(pocket => pocket.type === typeId).forEach(pocket => pocket.type = null);
            this.pocket.pocketTypes.splice(idx, 1);
        },

        nonUniqueNames(checkableNames) {
            let names = new Set();
            let nonUniqueNames = new Set();
            checkableNames.forEach(p => {
                if (names.has(p)) {
                    nonUniqueNames.add(p);
                }
                names.add(p);
            });
            return nonUniqueNames;
        },

        save() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$api.pockets.savePockets(this.pocket)
                .then(this.loadResponse)
                .then(() => this.$success("Mentés sikeres"));
        },

        cancel() {
            this.$checkDirty().then(this.load);
        },

        load() {
            this.$api.pockets.getPockets()
                .then(this.loadResponse);
        },

        loadResponse(response) {
            this.pocket = response;
            this.$resetDirty();
        },
    },

    mounted() {
        this.load();
        this.$registerDirtyListenersFor(['pocket']);
    }
};
</script>
