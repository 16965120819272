<template>
    <v-badge overlap color="transparent" :offset-x="offsetX" :offset-y="offsetY">
        <template #badge v-if="value.unexpected">
            <v-icon x-small color="error">mdi-alert-outline</v-icon>
        </template>
        <span>{{ value.cost }}</span>
    </v-badge>
</template>
<script>

export default {
    name: 'cost-value',
    props: {
        value: {type: Object, required: true},
        offsetY: {type: [Number, String], default: 12},
        offsetX: {type: [Number, String], default: 5},
    },
};
</script>
