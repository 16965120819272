<template>
    <v-tooltip bottom open-delay="100">
        <template v-slot:activator="{ on }">
            <v-btn large :color="color" @click="$emit('input', iconName)" icon :outlined="isSelected"
                   :class="{'elevation-3': isSelected}">
                <v-icon v-on="on">
                    {{ iconName }}
                </v-icon>
            </v-btn>
        </template>
        {{ value }}
    </v-tooltip>
</template>

<script>
import materialDesignIcons from "@/utils/IconPicker/materialDesignIcons";

export default {
    name: 'icon-picker-item',
    props: {
        value: String,
        color: String,
        selectedIcon: String
    },

    computed: {
        isSelected() {
            return this.selectedIcon === this.iconName;
        },
        iconName() {
            return materialDesignIcons.getIconName(this.value);
        }
    },
};
</script>
