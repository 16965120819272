<template>
    <v-card>
        <v-card-title ref="summaryPanel">
            <v-row>
                <v-col cols="auto">
                    <div :class="labelClass">Bevétel</div>
                    <div :class="valueClass">{{ summary.allIncome | asCurrency }}</div>
                </v-col>
                <v-col class="text-right wrap">
                    <div :class="labelClass">Kiadás</div>
                    <div :class="valueClass">{{ summary.allExpense | asCurrency }}</div>
                    <div class="mt-n2" v-if="this.summary.unexpected">
                        <cost-value :value="{cost: formattedUnexpected, unexpected: true}" :class="labelClass"/>
                    </div>
                </v-col>
                <v-col cols="12" style="height: 1px" class="pa-0">
                    <slot name="middle" v-bind="{summary: summary, labelClass: labelClass, valueClass: valueClass}"/>
                </v-col>
                <v-col cols="auto">
                    <div :class="labelClass">Megtakarítás</div>
                    <div :class="valueClass">{{ summary.allSave | asCurrency }}</div>
                </v-col>
                <v-col class="text-right">
                    <slot name="default" v-bind="{summary: summary, labelClass: labelClass, valueClass: valueClass}"/>
                </v-col>
            </v-row>
        </v-card-title>
    </v-card>
</template>
<script>
import {toCurrency} from "@/utils/CurrencyField/CurrencyUtils";
import {toPath} from "@/utils/DatePicker/DateUtils";
import PlatformInfoMixin from "@/utils/platform/PlatformInfoMixin";
import CostValue from "@/cost/components/CostValue";

export default {
    name: 'cost-summary',
    components: {CostValue},
    mixins: [PlatformInfoMixin],

    data: () => ({
        summary: {
            allExpense: 0,
            unexpected: 0,
            allIncome: 0,
            allSave: 0,
            difference: null
        },
    }),

    computed: {
        labelClass() {
            return this.isOnAndroid ? 'caption' : 'body-2';
        },
        valueClass() {
            return this.isOnAndroid ? 'subtitle-1' : 'h6';
        },
        unexpectedPercent() {
            return this.summary.allExpense === 0 ? 0 : Math.round(this.summary.unexpected / this.summary.allExpense * 100);
        },
        formattedUnexpected() {
            return `${toCurrency(this.summary.unexpected)} (${this.unexpectedPercent}%)`;
        }
    },

    filters: {
        asCurrency: value => toCurrency(value),
        capitalize: value => value ? value.charAt(0).toUpperCase() + value.slice(1) : value
    },

    methods: {
        refreshSummary(monthRange, search, average = false, onlyUnexpecteds = false) {
            this.$api.costs.getSummary(toPath(monthRange), search, average, onlyUnexpecteds).then(summary => this.summary = summary);
        },
    },
};
</script>
