import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [];

// Import all of the resource routes files.
function loadRoutes() {
    const context = require.context('@', true, /.*\.routes.js$/i);
    return context.keys()
        .map(context) // import module
        .map(m => m.default); // get `default` export from each resolved module
}

const resourceRoutes = loadRoutes();
resourceRoutes.forEach((route) => route.forEach(r => routes.push(r)));

const router = new VueRouter({routes});
export default router;
