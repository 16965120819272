export default ctx => ({
    integration: {
        getCurveIntegration() {
            return ctx.$axios.get("/curve").then(response => response.data);
        },
        saveCurveIntegration(pockets) {
            return ctx.$axios.put("/curve", pockets).then(response => response.data);
        },
        lastImport() {
            return ctx.$axios.get("/curve/lastImported").then(response => response.data);
        },
        importRows(csv, all = false) {
            return ctx.$axios.put(`/curve/import?importAll=${all}`, csv, {headers: {"Content-Type": "text/csv"}})
                .then(response => response.data);
        },
    }
});
