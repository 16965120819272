<template>
    <div>

        <app-bar-menu v-if="isOnAndroid" v-touch="{down: () => refreshList()}">
            <template #default="{ color }">
                <span :class="[`${color}--text`, 'headline-2']">Költségek</span>
                <v-spacer/>
                <debounced-search-field v-model="search" style="width: 100px" v-if="noSelection"/>
                <month-filter-picker v-model="monthRange" v-if="noSelection" color="primary"/>
                <v-btn icon @click="onlyUnexpecteds = !onlyUnexpecteds" color="primary" v-if="noSelection">
                    <v-icon>{{ onlyUnexpecteds ? 'mdi-alert' : 'mdi-alert-outline' }}</v-icon>
                </v-btn>
                <v-btn icon @click="openMultiCostForm" color="primary" v-if="hasAnySelection"
                       :disabled="singleSelection">
                    <v-icon>$edit</v-icon>
                </v-btn>
                <v-btn icon @click="deleteCosts" color="error" v-if="hasAnySelection" :disabled="hasImportedSelected">
                    <v-icon>$delete</v-icon>
                </v-btn>
            </template>
        </app-bar-menu>

        <form-dialog ref="formDialog" @save="saveCost" @refresh="refreshList">
            <template #default="{on}">
                <cost-edit-panel ref="costForm" v-bind="actualCost" v-on="on"/>
            </template>
        </form-dialog>

        <form-dialog ref="multiCostDialog" @save="saveMultiCosts" @refresh="refreshList">
            <template #default="{on}">
                <cost-multi-edit-panel ref="multiCostForm" :ids="selectedCostIds" v-on="on"/>
            </template>
        </form-dialog>

        <v-container fluid>
            <v-row>
                <v-col cols="5" v-if="!isOnAndroid">
                    <cost-desktop-search :search.sync="search" :month-range.sync="monthRange"
                                         :only-unexpecteds.sync="onlyUnexpecteds"/>
                    <cost-summary ref="costSummary" class="mt-6">
                        <template #default>
                            <div class="body-2">Kiválasztott elemek száma</div>
                            <div class="h6 mb-n8">{{ selectedCosts.length | asCurrency }}</div>
                            <v-fab-transition>
                                <v-btn color="primary" dark fab right small class="mb-n16 mr-n9" @click="refreshList">
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </v-fab-transition>
                        </template>
                    </cost-summary>
                </v-col>

                <v-col>
                    <div v-if="isOnAndroid" class="text-center" v-touch="{down: () => refreshList()}">
                        {{ monthRange | asHunDate }}
                    </div>

                    <v-card :flat="isOnAndroid">
                        <v-card-text :class="{'pa-0': isOnAndroid}">
                            <virtual-scroll-table :headers="headers" :items="costs" v-model="selectedCosts"
                                                  :item-height="costItemHeight"
                                                  :bottom-padding="bottomPadding"
                                                  :loading="loading" :show-select="!isOnAndroid" :search="search"
                                                  store-name="CostList">
                                <template #item="data">
                                    <cost-list-item v-bind="data" class="mx-1"
                                                    @edit="openCostForm('Módosítás', $event.id)"
                                                    @move="openCostForm('Mozgatás', $event.id, $event.cost)"/>
                                </template>
                                <template #header.actions>
                                    <v-btn icon color="primary" class="ml-n2" @click="openMultiCostForm"
                                           :disabled="noSelection || singleSelection">
                                        <v-icon small>$edit</v-icon>
                                    </v-btn>
                                    <v-btn icon style="margin-left: 6px;" @click="deleteCosts"
                                           :disabled="hasImportedSelected">
                                        <v-icon color="error">$delete</v-icon>
                                    </v-btn>
                                </template>
                            </virtual-scroll-table>

                            <v-fab-transition v-if="!isOnAndroid">
                                <v-btn color="primary" dark fixed right fab small @click="openCostForm('Rögzítés')"
                                       class="mr-n1 mt-n6">
                                    <v-icon>$create</v-icon>
                                </v-btn>
                            </v-fab-transition>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import {thisMonth, toHunDate, toRange} from "@/utils/DatePicker/DateUtils";
import MonthFilterPicker from "@/utils/DatePicker/MonthFilterPicker";
import VirtualScrollTable from "@/utils/table/VirtualScrollTable";
import DebouncedSearchField from "@/utils/search/DebouncedSearchField";
import PlatformInfoMixin from "@/utils/platform/PlatformInfoMixin";
import AppBarMenu from "@/utils/menu/AppBarMenu";
import CostSummary from "@/cost/components/CostSummary";
import CostDesktopSearch from "@/cost/components/CostDesktopSearch";
import CostListItem from "@/cost/components/CostListItem";
import CostDimensionMixin from "@/cost/components/CostDimensionMixin";
import AuthenticationMixin from "@/auth/AuthenticationMixin";
import FormDialog from "@/utils/form/FormDialog";
import CostEditPanel from "@/cost/components/CostEditPanel";
import CostMultiEditPanel from "@/cost/components/CostMultiEditPanel";
import {toCurrency} from "@/utils/CurrencyField/CurrencyUtils";

export default {
    name: "cost-list",
    mixins: [PlatformInfoMixin, CostDimensionMixin, AuthenticationMixin],
    components: {
        CostMultiEditPanel,
        CostEditPanel,
        FormDialog,
        CostListItem,
        CostDesktopSearch, CostSummary, AppBarMenu, DebouncedSearchField, VirtualScrollTable, MonthFilterPicker
    },

    data: () => ({
        costs: [],
        selectedCosts: [],
        monthRange: thisMonth(),
        loading: false,
        search: null,
        onlyUnexpecteds: false,
        actualCost: {id: null, moveFrom: null}
    }),

    computed: {
        headers() {
            let headers = [
                {text: "Dátum", value: "date",},
                {text: "Összeg", value: "cost",},
                {text: "Kategória", value: "categoryName"},
                {text: "Megjegyzés", value: "description", align: "center"}
            ];
            if (!this.isOnAndroid) {
                headers.push({text: "", width: 110, sortable: false, value: "actions"});
            }
            return headers;
        },
        hasImportedSelected() {
            return this.noSelection || this.selectedCosts.filter(it => it.imported).length > 0;
        },
        noSelection() {
            return this.selectedCosts.length === 0;
        },
        hasAnySelection() {
            return this.selectedCosts.length > 0;
        },
        singleSelection() {
            return this.selectedCosts.length === 1;
        },
        selectedCostIds() {
            return this.selectedCosts.map(it => it.id);
        }
    },

    filters: {
        asCurrency: value => toCurrency(value),
        asHunDate: value => toHunDate(value)
    },

    methods: {
        refreshList() {
            this.loading = true;
            let services = [this.$api.costs.getCosts(toRange(this.monthRange), this.search, this.onlyUnexpecteds)
                .then(costs => this.costs = costs)];
            if (!this.isOnAndroid) {
                services.push(this.$refs.costSummary.refreshSummary(this.monthRange, this.search, false, this.onlyUnexpecteds));
            }
            Promise.all(services)
                .finally(() => this.loading = false);
        },

        openCostForm(title, id, moveFrom) {
            this.actualCost = {id: id, moveFrom: moveFrom};
            this.$refs.formDialog.openFormDialog(title);
        },

        saveCost() {
            this.$refs.costForm.save();
        },

        openMultiCostForm() {
            this.$refs.multiCostDialog.openFormDialog('Költségek szerkesztése');
        },

        saveMultiCosts() {
            this.$refs.multiCostForm.save();
        },

        deleteCosts() {
            let deletionMessage = {
                title: this.selectedCosts.length === 1
                    ? "Valóban törölni akarja a költséget?"
                    : "Valóban törölni akarja a(z) " + this.selectedCosts.length + " kijelölt költséget?",
                text: "A költség törlés után nem visszaállítható.",
                acceptText: "Törlés",
                dialogMaxWidth: 600
            };
            this.$confirm(deletionMessage)
                .then(() => this.$api.costs.deleteCosts(this.selectedCosts.map(item => item.id)))
                .then(() => this.$success('Sikeres törlés'))
                .then(() => this.selectedCosts = [])
                .then(() => this.refreshList());
        },

        loadWhenSignedIn() {
            this.refreshList();
        }
    },

    watch: {
        search() {
            this.refreshList();
        },

        monthRange() {
            this.refreshList();
        },

        onlyUnexpecteds() {
            this.refreshList();
        },
    },
};
</script>
