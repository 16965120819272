import Vue from 'vue';
import Vuex from 'vuex';

const requireModules = require.context('@', true, /.*\.store\.js$/i);

const modules = {};

requireModules.keys().forEach(filename => {
    // create the module name from fileName
    // remove the directories and the store.js extension and capitalize
    const moduleName = filename
        .replace(/.*\//g, '')
        .replace(/\.store\.js/g, '')
        .replace(/^\w/, c => c.toUpperCase());

    modules[moduleName] = requireModules(filename).default || requireModules(filename);
});

Vue.use(Vuex);

export default new Vuex.Store({
    modules
});
