export default ctx => ({
    groups: {
        getGroups() {
            return ctx.$axios.get("/usergroup")
                .then(response => response.data);
        },
        getGroup(id) {
            return ctx.$axios.get("/usergroup/" + id)
                .then(response => response.data);
        },
        saveGroup(group) {
            return ctx.$axios.put("/usergroup", group);
        },
    }
});
