var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('simple-crud-list',_vm._b({on:{"save":_vm.save},scopedSlots:_vm._u([{key:"form",fn:function(ref){
var id = ref.id;
var on = ref.on;
return [_c('category-form',_vm._g({ref:"form",attrs:{"id":id}},on))]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('name-with-icon',{attrs:{"value":item}})]}},{key:"item.monthlyLimit",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[_vm._v(_vm._s(_vm._f("asCurrency")(value)))]):_vm._e()]}},{key:"item.type",fn:function(ref){
var value = ref.value;
return [_c('v-icon',{attrs:{"color":_vm.getIconDef(value).color,"small":""}},[_vm._v(" "+_vm._s(_vm.getIconDef(value).icon)+" ")])]}}])},'simple-crud-list',_vm.options,false))}
var staticRenderFns = []

export { render, staticRenderFns }