export const DirtyCheck = {
    data() {
        return {
            _dirty: false
        };
    },
    methods: {
        $resetDirty() {
            this.$nextTick(function () {
                this._dirty = false;
            });
        },
        $setDirty() {
            this._dirty = true;
        },
        $checkDirty() {
            if (this._dirty) {
                return this.$confirm({
                    title: "Módosítások még nem lettek elmentve",
                    text: "El akarod dobni a változásokat?",
                    acceptText: "Elvet"
                });
            }
            return Promise.reject("Not dirty");
        },
        $registerDirtyListenersFor(watchables) {
            if (!Array.isArray(watchables)) {
                watchables = [watchables];
            }
            watchables.forEach(p => this.$watch(p, this.$setDirty, {deep: true}));
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this._dirty) {
            this.$checkDirty().then(() => next()).catch(() => next(false));
        } else {
            next();
        }
    }
};
